@media (min-width:992px) {

    .master-img2 {
        display: none !important;
    }

    #address-sm {
        display: none !important;
    }

    .faq-image-align {
        padding-right: 70px !important;
    }

    .mob-trade-img-2 {
        padding-bottom: 2%;
    }

    #scrrener1-mob {
        display: none !important;
    }

    #pamm3-head2 {
        display: none !important;
    }

    /* #stratagy {
      display: none !important;
  } */
}

@media (max-width:540px) {
    .abt-tx-p-si-pamm {
        font-size: 19px !important;
    }

    .description {
        line-height: 25px !important;
    }

    .mr-etf-1 {
        margin-top: 45px !important;
        margin-bottom: 50px !important;
    }

    .mr-etf-2 {
        margin-top: 2px !important;
        margin-bottom: 40px !important;
    }

    .txt-etf-h-1 {
        margin-top: 0px !important;
    }

    #pamm3-head1 {
        display: none !important;
    }

    .pamm-3-col {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pamm-mob-col {
        padding-top: 25px !important;
    }

    .fw-pamm-2,
    .fw-pamm-3 {
        margin-left: 43px !important;
    }

    .pamm-ico-2 {
        margin-top: 0px !important;
    }

    .mobile-pamm-row {
        padding-bottom: 40px;
    }

    .pamm-img-space {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .red-col {
        margin-top: 15px;
        background-repeat: no-repeat;
        background-size: 100%;
        height: auto;
        padding-top: 25px;
        padding-bottom: 35px;
        /* background-image: url('../../src/images/ctradeimg.png'); */
        background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    }

    .trading-promotion-five .reward-calculate-text {
        border-radius: 10px;
        box-shadow: 0 0 6px #00000042;
        width: 100%;
    }

    .reward-calculate-text .account-progress-text {
        font-size: 13px;
        font-weight: normal;
        color: #000000;
        width: 23ch;
    }

    .trading-promotion-five .reward-total {
        text-align: center;
        padding-bottom: 50px !important;
    }

    .ib-six-align3 {
        padding-top: 77px !important;
        padding-bottom: 5% !important;
    }

    .reward-calculate-text .range-two-text input {
        width: 121% !important;
    }

    .trading-pg-head {
        line-height: 45px !important;
    }

    .ib-four-bg-two {
        padding-top: 7% !important;
        padding-bottom: 7% !important;
    }

    .ourplat-left-bg-new,
    .saftysix-bg-new {
        padding-bottom: 40px;
        background-size: 100% !important;
        background-image: none !important;
    }

    .mt5-two-bg-ctrade {
        padding-bottom: 0px !important;
    }

    #co-bg-re {
        padding-bottom: 30px !important;
    }

    .mobile3-img-new {
        padding-left: 0% !important;
    }

    .mobile-three-1bg {
        padding-left: 15px !important;
    }

    .mobile-three-3bg {
        padding-left: 20px !important;
    }

    #scrrener1 {
        display: none !important;
    }

    .social-links-mob {
        justify-content: center !important;
        display: flex !important;
    }

    .fw-pamm-1 {
        text-align: center !important;
        margin-left: 0px !important;
    }

    .stying-btn {
        margin-top: 7% !important;
        margin-bottom: 7% !important;
    }

    .stay-bg {
        background-size: 100% !important;
    }

    .about6-four-cont-1 {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }

    .wiget-mobile {
        overflow-x: scroll !important;
    }

    .first-col {
        display: none;
    }

    #address-lg {
        display: none !important;
    }

    .copier-two-bg {
        background-size: cover !important;
        height: auto !important;
        padding-bottom: 10px !important;
    }

    .nav-btn1 {
        margin-top: 20px !important;
    }

    .nav-btn {
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }

    .text-section,
    .des-two,
    .des-three,
    .ib-text-section,
    .ib-des-two {
        margin-top: 25px !important;
    }

    .ib-one-bg {
        padding-top: 60px !important;
    }

    .ib-one-img {
        height: 210px !important;
    }

    .pamm-cont-circle {
        margin-top: 30px;
    }

    .pamm-one-btn {
        margin-top: 30px;
    }

    .txt-cl-pam {
        font-size: 40px !important;
    }

    .pamm-hero {
        padding-top: 35% !important;
    }

    .master-img1 {
        display: none !important;
    }

    .about6-three-col {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .about8-three-bg {
        background-image: none !important;
        height: auto !important;
    }

    .our-pla-bg {
        height: auto !important;
    }

    .commod-bg {
        height: auto !important;
    }

    .commod-bg2 {
        height: auto !important;
    }

    .About8-three-cont2 {
        height: auto !important;
        margin-bottom: 20px !important;
    }

    .About8-three-cont {
        /* height: auto !important; */
        margin-bottom: 20px !important;
    }

    .about8-two-img {
        height: 300px !important;
        position: static !important;
    }

    .about5-cont4 {
        padding: 20px 10px !important;
        margin-bottom: 20px;
    }

    .About5-four-img {
        height: 250px !important;
    }

    .about5-four-bg {
        padding-bottom: 1px !important;
    }

    .about5-five-submit {
        margin-bottom: 40px !important;
    }

    .bond2-bg {
        padding-bottom: 0px !important;
    }

    .bond3-bg {
        background-image: none !important;
        padding-bottom: 0px !important;
    }

    .forex3-bg {

        background-image: none !important;
        padding-bottom: 130px !important;

    }

    .shares-bg {
        background-image: none !important;
        padding-bottom: 0px !important;
    }

    .mar-tp-txt-1 {
        margin-left: 0px !important;
    }

    .ETF4-bg,
    .ETF5-bg,
    .ETF6-bg,
    .ETF7-bg {
        background-image: none !important;
    }

    .ETF4-img {
        margin-top: 0px !important;
    }

    .mt5-six-img {
        height: 260px;
        position: static !important;
        left: 0% !important;
    }

    .mt5-six-cont2 {
        margin-bottom: 20px;
    }

    .mt5-six-cont {
        margin-top: 50px !important;
    }

    .mt5-three-img {
        height: 270px !important;
    }

    .trading3-widget {
        margin-bottom: 20px !important;
        margin-top: 20px !important;
    }

    .trading2-bg {
        padding-top: 30px !important;
        padding-bottom: 40px !important;
    }

    .Trading-sec-one {
        background-image: none !important;
    }

    .Trading-cont {
        padding-top: 0px !important;
        padding-bottom: 20px !important;
    }

    .sec-one {
        margin-top: 30px !important;
        height: 400px !important;
    }

    .Trading-sec-one {
        margin-top: 30px !important;
    }

    .boxes-design2 {
        margin-top: 60px;
    }

    .sec-four-p {
        height: 210px !important;
    }

    .sec-one-p {
        height: 300px !important;

    }

    .trading1-img {
        height: 300px !important;
        margin-left: 0px !important;
    }

    .sec-five-left-align {
        margin-top: 30px;
    }

    .sec-six-left-align {
        margin-top: 35px;
    }

    .red-bg-phone {
        height: 330px !important;
    }

    .ib-head-align {
        margin-top: 0px !important;
    }

    .ib-one-head {
        font-size: 20px;
    }

    .ib-one-text {
        line-height: 40px !important;
    }

    .ib-two-bg {
        height: 218px !important;
    }

    .ib-three-bg {
        background-position: bottom !important;
        background-size: 100% 20% !important;
    }

    .navbar-space {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .nav-btn1,
    .nav-btn {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

body {
    overflow-x: hidden !important;
}

/* Navbar */
.navbar-space {
    padding-left: 35px;
    padding-right: 35px;
}

body {
    font-family: 'Poppins', sans-serif;
}

.nav-logo-txt {
    font-size: 30px !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: #ff5848 !important;
}

#collasible-nav-dropdown .ddlink {
    font-weight: 500;
    text-decoration: none;
    color: #737373;
    font-size: 14px;
}

#collasible-nav-dropdown .nav-dd-active {
    color: #ff5848;
}

.dropdown-item:focus,
.dropdown-item:hover {
    transition: 0.4s;
    background-color: #ff5848;
    color: #fff;
}


.nav-links {
    font-weight: 500;
    text-decoration: none;
    color: #737373;
    font-size: 14px;
}

.nav-links-active {
    color: #ff5848 !important;
}

/* #collasible-nav-dropdown {
  position: relative;
  top: 2px;
} */

.nav-links:hover,
#collasible-nav-dropdown:hover {
    color: #ff5848 !important;
}

.nav-btn {
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    font-size: 14px !important;
    border-radius: 30px;
    margin-left: 15px;
    width: 135px;
}

.nav-btn:hover {
    color: #000;
    background-color: #ff5848;
}

.nav-btn1 {
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    background-color: #ff5848;
    font-size: 14px !important;
    border-radius: 30px;
    margin-left: 50px;
    width: 135px;
    animation: mymove 3s infinite;
}

@keyframes mymove {
    50% {
        box-shadow: 3px 1px 25px #eb210f;
    }
}

@keyframes mymove1 {
    50% {
        filter: drop-shadow(0px 30px 90px #fa807257);
    }
}

.nav-btn1:hover {
    color: #000;
    background-color: #ff5848;
    animation: none;
}


.nav-space {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.nav-bg {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: #fff;
}

.nav-bg2 {
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all .2s ease-in-out;
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-50px);
    }

    100% {
        transform: translateY(0px);
    }
}

.logo-head-align {
    height: 30px;
}

/* Navbar */
/* Footer */
.footer-bg {
    background-color: #1a1a1a;
    padding-top: 35px;
    overflow-x: hidden;
}

.footer-forex {
    font-size: 18px;
    font-weight: 500;
    color: #ff5848;
    text-align: left;
}

.footer-ul {
    text-align: left;
    padding-left: 20px;
    color: #d7d7d7;
    list-style-type: initial;
}

.footer-ul2 {
    text-align: left;
    padding-left: 10px;
    color: #d7d7d7;
    list-style-type: none;
}

.footer-liks {
    text-decoration: none;
    color: #d7d7d7;
    font-size: 15px;
    font-weight: 300;
}

.footer-liks2 {
    text-decoration: none;
    color: #d7d7d7;
    font-size: 14px;
    font-weight: 300;
}

.footer-liks-copy {
    text-decoration: none;
    color: #d7d7d7;
    font-size: 14px;
    font-weight: 300;
}

.footer-liks:hover {
    color: #ff5848;
}

.footer-liks2:hover {
    color: #ff5848;
}

#Email-Form ::placeholder {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

#Email-Form :focus {
    box-shadow: none;
}

.logo-img {
    height: 35px;
}

.footer-bootom-align {
    margin-top: 20px;
}

.footer-para {
    color: #d7d7d7;
    font-size: 14px;
    text-align: justify;
    font-weight: 300;
}

.footer-para2 {
    font-size: 14px;
    text-align: justify;
    color: #000;
    font-weight: 300;
}

.footer-cont-sm {
    border-radius: 4px;
    background-color: #fff;
    padding: 25px 20px 10px 20px;
    /* margin-bottom: 20px; */
}

.copyright-cont {
    margin-bottom: 60px;
}

.risk-warning {
    background-color: #ff5848;
    font-size: 18px;
    text-decoration: none;
    pointer-events: none;
    padding: 6px 8px !important;
    border-radius: 4px;
    text-align: left !important;
}

.risk-position {
    position: relative;
    top: 35px;
    left: 20px;
}

.brand-icon {
    color: #fff;
    margin: 40px 0px;
}

.brand-icon:hover {
    color: #ff5848;
    margin: 40px 0px;
    cursor: pointer;
}

#basic-addon2 {
    background-color: #ff5848;
}

#basic-addon2:hover {
    background-color: #000;
    cursor: pointer !important;
}

.links-icons {
    color: #ff5848;
    padding-right: 10px;
}

/* Footer */

.grey-txt {
    color: #455a64;
}

.fw-bo {
    text-align: left;
    font-weight: 600;
    margin-top: 90px;
    font-size: 40px;
}

.fw-bo-new {
    text-align: left;
    font-weight: 600;
    font-size: 40px;
}

.fw-bo-1 {
    text-align: left;
    font-weight: 600;
    color: #ff5848;
    font-size: 40px;
}

.fw-bo-2 {
    text-align: left;
    font-weight: 400;
    margin-top: 30px;
    font-size: 14px;
}

.broker-btn {
    background-color: #ff5848;
    color: #ffffff;
    border: none;
    font-size: 18px !important;
    margin-top: 20px;
}

.broker-btn2 {
    background-color: #ff5848;
    color: #ffffff;
    border: none;
    font-size: 16px !important;
    position: relative;
    top: 22%;
}

.broker-btn:hover {
    background-color: #000;
    color: #fff;
    /* border: 1px solid #fff; */
}

.sec-one-p {
    height: 380px;
    margin-top: 30px;
    /* animation: mymove1 3s infinite; */
    /* filter: drop-shadow(0 30px 90px #fa807257); */
    /* -webkit-box-shadow: 0 30px 90px #fa807257; */
}

.sec-one {
    margin-top: 100px;
    margin-bottom: 60px;
    height: 528px;
}

.sec-third-p {
    margin-top: 80px;
}

.sec-third-space {
    margin-bottom: 120px;
}

.boxes-design {

    border-radius: 10px;
    /* margin-top: 35px !important; */
    padding: 0px 20px 20px 20px !important;
    max-height: 400px;
    max-width: 300px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

/* body {
  background-color: #fff5f4;
} */

.fw-bo-fou {
    text-align: left;
    font-weight: bolder;

}


.fw-bo-2-fo {
    text-align: left;
    font-weight: 600;
    margin-top: 30px;
    font-size: 18px;

}

/*Section Two*/
.sec-two-cont-align {
    margin-top: 30px;
}

.sec-two-para {
    line-height: 30px;
}

.border-left {
    border-left: 2px solid #fa8072;
    padding-left: 20px;
}

.tick {
    height: 50px;
}

.dollar {
    height: 50px;
}

.trading-img {
    height: 50px;
}

.open-acc-btn {
    background-color: #ff5848;
    color: #fff;
    border-color: #ff5848;
}

.open-acc-btn:hover {
    background-color: #ff5848;
    color: #000;
    border-color: #ff5848;
}

/*End of Section Two*/

.five-tick {
    height: 40px;
}

.sec-3-ico {
    height: 40px !important;
}

.ico-cont {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    background-color: #fff;
    padding: 8px 8px;
    max-width: 60px;
    border-radius: 5px;
    position: relative;
    bottom: 16%;
}

.box-head {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    color: #535759;
}

.sec3-ul {
    text-align: left;
    font-size: 14px;
    padding-top: 15px;
    list-style-type: initial;
}

.sec-four-p {
    height: 335px;
    margin-top: 40px;
}

.sec-fifth-align {
    margin-top: 40px;
    margin-bottom: 40px;
}

.red-bg-phone {
    height: 420px;
}

.cont-bg-color {
    background-color: #fff0ef;
    padding: 6%;
}

.off-btn {
    background-color: #ff5848;
    color: #fff;
    border-color: #ff5848;
}

.off-btn:hover {
    background-color: #ff5848;
    color: #000;
    border-color: #ff5848;
}

.border-right {
    border-left: 2px solid #fa8072;
    padding-left: 20px;
}

.sec-six-align {
    margin-top: 30px;
    margin-bottom: 50px;
}

/* Trading Page */
/* Section one */
.fw-trading {
    /* text-align: left; */
    font-weight: 400;
    margin-top: 10px;
    font-size: 16px;
}

.fw-trading-new {
    /* text-align: left; */
    font-weight: 400;
    margin-top: 10px;
    font-size: 14px;
    max-width: 400px;
}

.fw-bo-trading {
    /* text-align: left; */
    font-weight: 600;
    margin-top: 60px;
    font-size: 39px;
}

.fw-bo-trading-new {
    /* text-align: left; */
    font-weight: 600;
    /* margin-top: 60px; */
    font-size: 39px;
}

.Trading-btn {
    background-color: #bf1b37;
    color: #ffffff;
    border: none;
    font-size: 13px !important;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.Trading-btn:hover {
    background-color: #ff5848;
    color: #000;
}

.Trading-cont {
    padding-top: 80px;
    padding-bottom: 60px;
}

.Trading-sec-one {
    background-position: cover;
    background-size: 100%;
    margin-top: 60px;
    background-repeat: no-repeat;
    background-image: url("../images/trade-home.svg");
}

.trading1-img {

    height: 380px;
    margin-top: 30px;
    margin-left: 60px;
}

.trading1-icons {
    height: 20px;
    position: relative;
    top: 8px;
    left: 16px;
}

.pr-0 {
    padding-right: 0px !important;
}

/* End Of Section one */
/* Section 2 */
.trading2-bg {
    padding-top: 170px;
    padding-bottom: 30px;
    background-image: url('../../src/images/forex-bg-three.svg');
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 50px;
}

/* End of Section 2 */
/*Section 3 */
.trading3-img {
    height: 70px;
    margin: 20px 0 20px 0 !important;
    margin-right: 0px !important;
}

.trading3-img2 {
    height: 90px;
    margin: 20px 0 0px 0 !important;
    position: relative;
    bottom: 10px;
}


.trading3-bg {
    background-color: #000;
    padding-top: 40px;
    padding-bottom: 40px;

}

/* .trading3-cont {
  padding-top: 40px;
  padding-bottom: 40px;
} */

.fw-bo-trading3 {
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
}

.trading3-para {
    margin-top: 20px;
    font-size: 16px;
    max-width: 490px !important;
}

/* End Of Section 3 */
/* End of Trading Page */
.trade-four-align {
    margin: 7% 0% 4% 0%;
    background-image: url("../images/trading-bg-one.svg");
    background-size: 100%;
    padding: 50px 0px 50px 0px;
    background-repeat: no-repeat;
}

.access-align-one {
    margin-top: 60px;
}

.access-align-two {
    margin-top: 40px;
    margin-left: 20px;
}

.members-img {
    height: 400px;
}

.trade-five-align {
    background: url("../images/trading-bg-two.svg");
    background-size: 100%;
    padding: 50px 0px 50px 0px;
    background-repeat: no-repeat;
}

.markets-align-one {
    margin-top: 60px;
}

.markets-align-two {
    margin-top: 40px;
    margin-left: 10px;
}

.tools-bg {
    background-color: #F3E8E5;
    border-radius: 10px;
    padding: 30px 30px 0px 30px;
}

.tools-outer-bg {
    padding: 5% 0% 7% 0%;
    background-image: url("../images/tools-bg.svg");
    background-size: 115%;
    background-repeat: no-repeat;
}

.tools-opt {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 5px 0px 5px 0px;
}

.mar-align {
    margin-top: 20px;
}

.tools-text-align {
    line-height: 24px;
}

.tab-phone-img {
    margin-top: 30px;
}

/* Trading-seven */
.trade-seven {
    padding-top: 50px;
    padding-bottom: 30px;
}

.boxes-design-trade {
    border-radius: 10px;
    padding: 0 30px 0 30px !important;
    max-height: 173px;
    max-width: 350px;
    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border: 1px solid hwb(240deg 91% 6%);
}

.star-trade {
    width: 128px;
}

.inter-tr {
    height: 210px;
}

.file-tr {
    height: 225px;
}

.tp-si-tr {
    padding-top: 20px;
}

.bt-si-tr {
    margin-bottom: 50px;
}

.com-tr {
    width: 600px;
}

.mrtp-comm {
    margin-top: 70px;
}

.fw-bo-2-tr {
    font-weight: 500;
}

.fw-bo-2-tr-si {
    margin-top: 30px;
    font-weight: 500;
}

.tra-acc {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

@media (max-width:540px) {

    .mb-si-tp {
        margin-top: 30px;
    }

    .mb-sicom {
        margin-top: -30px;
    }

    .tools-opt {
        padding: 5px;
    }

    .mt-5-one {
        background-image: none !important;
        height: auto !important;
        padding-bottom: 30px !important;
    }

    .mt-5-sp {
        margin-top: -160px;
    }

    .mobile5-img-new {
        position: static !important;
        margin-top: 100% !important;
        height: auto !important;
    }

    .faq3-img-new {
        position: static !important;
        margin-top: 100% !important;
        height: auto !important;
    }

    .mobile5-img-mobile {
        display: none !important;
    }
}

/* mt5-one */

.mt-5-one {
    /* padding-bottom: 250px; */
    background-size: 100%;
    margin-top: 60px;
    background-repeat: no-repeat;
    background-image: url("../images/mt5-bg.svg");
    height: 700px;
}

.mt-5-sp {
    padding-top: 100px;
}

.fw-bo-mt5 {
    font-weight: 650;
    margin-top: 90px;
    font-size: 50px;
}

.mt5-sing {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 15px 20px 15px 20px;
}

.mt5-sing-new {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    /* padding: 10px 40px 10px 40px; */
    width: 150px;
}

.mt5-sing:hover {
    background-color: #000;
    color: #fff;
}

.mr-mt-5 {
    margin-top: 20px;
    font-size: 20px;
}

.mt-5-logo-img {
    width: 600px;
}

.ctrader-img {
    padding-top: 90px;
    height: 400px;
}

.mt5-card {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 30px;
    height: 330px;
}

.mt5-two-img {
    height: 40px;
    margin-bottom: 25px;
    margin-top: 15px;
}

.mt5-two-bg {
    background-color: #fff;
}

.mt5-two-cont {
    padding: 40px;
}

.mt5-three-img {
    height: 370px;
}

.mt5-three-cont {
    padding-top: 10px;
    padding-bottom: 50px;
}

.mt5-three-btn {
    background-color: #000 !important;
    color: #fff !important;
    border: none;
    font-size: 13px;
    padding: 10px 25px !important;
    margin-top: 20px;
}

.mt5-three-btn:hover {
    background-color: #ff5848 !important;
    color: #000 !important;
}

.explore-txt {
    color: #d9072d;
    cursor: pointer;
    text-decoration: none;
    margin-right: 8px;
}

.explore-txt:hover {
    color: #000;
}

#faq-acc .accordion-button:not(.collapsed) {
    color: #ff5848 !important;
}

#faq-acc .accordion-item {
    border: 2px solid #e6e6e6;
    border-radius: 5px;
    margin-top: 10px;
}

#faq-acc .accordion-button:focus {
    box-shadow: none !important;
    border: none;
    background-color: transparent;
}

#faq-acc .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

.mt5-six-cont1 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #c2223d;
    margin-bottom: 80px !important;
}

.mt5-six-rate {
    height: 20px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    top: 4px;
}

.mt5-six-cont {
    background-color: #f5c2bb;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-top: 15px;
}

.mt5-six-img {
    height: 500px;
    position: relative;
    left: 9%;
}

.mt5-six-cont2 {
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 25px 20px 25px !important;
    margin-top: 20px;
}

.mt5-six-social {
    height: 25px;
    padding: 3px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
}

.create-account {
    padding: 15px 25px !important;
    background-color: #000;
    color: #fff;
    border: none;
    margin-top: 20px;
}

.create-account:hover {
    background-color: #ff5848;
    color: #000;
}

#mt5-form .form-control {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

#mt5-form .form-control:focus {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-color: #ff5848;
}

@media (min-width: 1200px) {
    .mt-5-one {
        height: 840px;
    }

    .tr-pl-sec-one {
        height: 850px !important;
    }

    .tr-pl-sec-one-abt {
        height: 840px !important;
    }

    .tr-pl-sec-one-pamm {
        height: 840px !important;
    }
}

.mobile-cont {
    border-radius: 10px;
    background-color: #f4f4f5;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 30px 30px 100px 30px;
}

.mobile3-bg {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.mobile3-img {
    height: 350px;
}

.mobile3-img-new {
    height: 350px;
    padding-bottom: 30px;
    padding-left: 30%;
}

.mobile-three-cont {
    padding-top: 30px;
}

.mobile3-cont2 {
    padding: 20% 5%;
}

.mobile-cont-space {
    padding-top: 10%;
    /* margin-top: 40px;
    margin-bottom: 40px; */
    padding-bottom: 4%;
}

.bobile-txt {
    margin-top: 10px;
    font-size: 17px;
}

.bobile-txt-head {
    font-size: 16px;
}

.app-btn {
    height: 45px !important;
    margin-top: 20px;
    z-index: 1;
    position: relative;
}

.app-btn2 {
    height: 45px !important;
    margin-top: 20px;
    z-index: 1;
    position: relative;
}

.mobile1-img {

    height: 600px;
    margin-top: 30px;
    position: relative;
    bottom: 13%;
}

.mbtrading-cont {
    padding-top: 80px;
}

.mobile-sec-one {
    background-position: cover;
    background-size: 100%;
    margin-top: 45px;
    background-repeat: no-repeat;
    background-image: url("../images/tra-pla-bg.svg");
    height: 700px;
}

.mobile5-img {
    height: 430px;
}

.mobile5-img-mobile {
    height: 570px;
}


.mobile5-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-color: #fff;
}

.dowload-cont {
    padding: 5% 0%;
}

.dowload-cont-new {
    padding-top: 10%;
    padding-bottom: 12%;
}

.mobile5-img-new {
    position: relative;
    top: 350px;
    /* top: 630% !important; */
}

.mobile5-top-cut {
    margin-top: -350px;
}

.mobile2-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: left;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    margin-top: -118px;
}

.mobile2-bg-new {
    padding-top: 60px;
    padding-bottom: 35px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    /* margin-top: -118px; */
}

.mobile1-cont {
    padding: 10% 0 !important;
}

.mobile2-img {
    height: 430px;
    position: relative;
    right: 8.5%;
    margin-bottom: 25px;
}

.mobile-bo-trading {
    font-weight: 600;
    margin-top: 60px;
    font-size: 40px;
}

@media (max-width:540px) {
    .mobile-bo-trading {
        margin-top: 0px !important;
    }

    .mobile-sec-one {
        height: auto !important;
        background-image: none !important;
    }

    .mbtrading-cont {
        padding-top: 0px !important;
    }

    .mobile1-img {
        height: 300px !important;
        margin-top: 0px !important;
        margin-bottom: 20px !important;
        position: static !important;
    }

    .mobile2-img {
        height: 300px !important;
        position: static !important;
    }

    .mobile2-bg {
        background-size: 100% 100% !important;
    }

    .about8-two-bg {
        background-size: 100% 100% !important;
    }

    .mobile3-cont2 {
        padding: 7% 0%;
    }

    .mobile5-bg {
        padding-bottom: 0px !important;
    }

    .trading1-icons {
        left: 35px !important;
    }
}

/* Trading-Platform */

.trade-platform-three {
    padding-top: 40px;
    background-color: #fff !important;
    padding-bottom: 80px;

}

.boxes-design-tradeing-platform {
    border-radius: 10px;
    padding: 60px 30px 140px 30px !important;
    max-height: 173px;
    max-width: 350px;
    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border: 1px solid hwb(240deg 91% 6%);
}

.tra-pla {
    margin-top: 10px;
}

.tr-pl-tx {

    font-weight: 600;

    font-size: 35px;
}

.red-text {
    color: #d80027;
}

.tr-pl-tx-si {
    margin-top: 45px;
}

.tr-pl-la {
    width: 600px;
}

.tr-pl-la-new {
    height: 350px;
}

.mar-tp {
    margin-top: 30px;

}

.mar-tp-txt {
    margin-left: 50px;
}

.mar-tp-txt-1 {
    margin-left: 30px;
}

.trade-platform-five {
    padding-top: 8%;
    background-color: #fff !important;
}

.trading-plt-six {
    padding-top: 70px;

}

/* Trading-Platform */

.trade-platform-three {
    padding-top: 130px;
    background-color: #fff !important;
    padding-bottom: 80px;

}

.boxes-design-tradeing-platform {
    border-radius: 10px;
    padding: 60px 30px 140px 30px !important;
    max-height: 173px;
    max-width: 350px;
    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border: 1px solid hwb(240deg 91% 6%);
}

.tra-pla {
    margin-top: 10px;
}

.tr-pl-tx {

    font-weight: 600;

    font-size: 35px;
}

.tr-pl-tx-si {
    margin-top: 45px;
}

.tr-pl-la {
    width: 600px;
}

.mar-tp {
    margin-top: 30px;

}

.mar-tp-txt {
    margin-left: 50px;
}

.mar-tp-txt-1 {
    margin-left: 30px;
}


.trading-plt-six {
    padding-top: 70px;

}

.tra-pl-im {
    width: 1000px !important;
}

.tr-pl-sec-one {
    background-position: cover;
    background-size: 100%;
    margin-top: 45px;
    background-repeat: no-repeat;
    background-image: url("../images/tra-pla-bg.svg");
    height: 700px;
}

.trpla-sing {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 12px 30px 12px 30px;
}

.tr-tx-he {
    font-size: 45px;
    font-weight: 610;
    margin-top: 70px;
}

.tr-tx-he-two {
    margin-top: 20px;
}

.mar-tp-txt-tr {
    margin-top: 30px;
}






@media (min-width:1900px) {
    .trading2-bg {
        padding-top: 379px !important;
        padding-bottom: 54px !important;
    }
}

@media (max-width:540px) {
    .tx-si-pamm-p-new1 {
        margin-top: 25px !important;
    }

    .mb-si-trade-pla {
        margin-top: 30px;
    }

    .tr-pl-sec-one {
        background-image: none !important;
        height: auto !important;
    }

    .tr-pl-sec-one-abt {
        background-image: none !important;
        height: auto !important;
    }

    .mobile1-cont {
        margin-top: -50px;
    }

    .tra-pl-im {
        padding-bottom: 50px;
    }

    .our-pla-bg {
        background-image: none !important;
    }

    .our-pla-align {
        padding: 19% 13% !important;
    }

    .commod-align {
        padding: 19% 13% !important;
    }

    .commod-align2 {
        padding: 19% 13% !important;
    }

    .bond-ph-one {
        margin-top: 20px !important;
    }

    .commod-img {
        margin-top: 20px !important;
    }

    .mb-bond-tp {
        margin-top: -40px;
    }

    .mb-bond-tp-two {
        margin-top: -110px;
    }

    .bg-wh-two-mb {
        margin-top: 20px;

    }

    .bg-about-gr {
        background-color: #f4f4f5;
        padding: 40px 5px 40px 25px !important;
        border-radius: 10px;
        position: relative;
        bottom: 70px;
    }

    .ourplat-left-bg {
        background: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 100% 100% !important;
    }

    .our-plat-two-img {
        height: 300px !important;
        padding-left: 0% !important;
    }

    .tx-si-pamm-p {
        margin-left: 5px !important;
    }

    .tx-si-pamm-h {
        margin-left: 5px !important;
    }

    .tr-pl-sec-two-pamm {
        background-image: none !important;
        padding-top: 3%;
        padding-bottom: 3%;

    }

    .tr-pl-sec-one-pamm {
        background-image: none !important;
        background-color: #f3e8e5 !important;
        height: auto !important;
        padding-bottom: 30px;
    }

    .mobile-pamm {
        margin-left: -1px !important;
        width: 500px !important;
        margin-left: 10px !important;
        margin-bottom: 130px !important;
        margin-top: -180px !important;
    }

    .mr-pamm-cont {
        margin-top: -160px;
    }

    .mb-pamm-one {
        margin-top: 10px !important;

    }



}


.our-pla-bg {
    background-image: url("../images/our-pla-bg-one.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 580px;
}

.commod-bg {
    background-image: url("../images/our-pla-bg-one.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 700px;
    margin-bottom: 30px;
}

.commod-bg2 {
    background-image: url("../images/our-pla-bg-one.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 730px;
    margin-bottom: 30px;
}

.our-pla-align {
    padding: 8%;
}

.commod-align {
    padding: 8% 2%;
}

.commod-align2 {
    padding: 10% 2% 2% 2%;
}

.our-plat-head {
    margin-top: 80px;
}

.our-plat-para {
    line-height: 25px;
}

.our-btn {
    margin-top: 40px;
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}

.our-btn-new {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
    margin-top: 20px;
}

.our-btn2 {
    margin-top: 5px;
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}

.our-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.our-btn-new:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.copier-btn {
    margin-top: 40px;
    background-color: #d80027;
    color: #fff;
    border-color: #d80027;
    padding: 12px 45px 12px 45px;
}



.copier-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.our-plat-para {
    margin-top: 20px;
}

.our-pla-bg-two {
    background-image: url("../images/our-pla-bg-two.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 637px;
}

.ourplat-tab-img {
    margin-top: 40px;
    height: 580px;
}

.ourplat-three-align {
    padding: 4% 0px 1% 0px;
}

.ourplat-left-bg {
    background: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50% 100%;
}

.our-plat-two-img {
    /* clip-path: circle(); */
    height: 450px;
    padding-left: 15%;
}

.ourplat-align {
    margin-top: 70px;
}

.mt4-phone-img {
    padding: 10px;
}

.mt5-phone-img {
    padding: 10px;
}

.our-plat-link-one {
    text-decoration: none;
    margin-top: 40px;
}

.our-plat-link-one:hover {
    text-decoration: none;
    color: #000;
}

.our-plat-link-two {
    text-decoration: none;
}

.our-plat-link-two:hover {
    text-decoration: none;
    color: #000;
}

.right-arrow-color {
    color: #737373;
}

.plat-four-align {
    margin: 50px 0px 50px 0px;
}

.our-card-align {
    margin-top: 50px;
}

.bond2-bg {
    padding-bottom: 100px;
    margin-bottom: 100px;
}

.bond3-bg {
    background-image: url('../images/forex-bg-two1.svg');
    background-repeat: no-repeat;
    background-position: left !important;
    padding-bottom: 140px;
    /* padding-top: 30px; */
}

.forex3-bg {
    /* background-image: url('../images/forex-bg-two1.svg');
  background-repeat: no-repeat;
  background-position: left !important;
  padding-bottom: 140px; */
    /* padding-top: 30px; */
    background-color: #f9e8e7;
    padding-bottom: 160px;
    padding-top: 30px;
}

.shares2-bg {
    background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
    padding-bottom: 40px;
    margin-bottom: -189px;
}

.shares2-bg-inner {
    background-image: url("../images/Rectangle7687.png");
}

.shares-bg {
    background-image: url('../images/forex-bg-two1.svg');
    background-repeat: no-repeat;
    /* background-size: 100%; */
    background-position: left !important;
    padding-bottom: 210px;
    background-color: #bf1b37 !important;
}

.bond-ph-one {
    width: 600px;
    margin-top: -50px;
}

.commod-img {
    width: 460px;
    margin-top: -50px;
}

.mr-tp-bond {
    margin-top: 90px;

}

.bo-ph-tw {
    margin-bottom: 100px;
}

.mr-bo-one-tx {
    margin-bottom: 30px;
}

.Commod-btn {
    background-color: #000;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 12px 20px 12px 20px;
}

.Commod-btn:hover {
    background-color: #ff5848;
}

.mb-cry {
    margin-bottom: 150px;
}

.indices5-bg {
    background-image: url('../images/indi-bg-one.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 150px 0 !important;
}

.ETF4-bg {
    background-image: url('../images/etf-bg.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    padding-top: 35px;
    /* padding: 150px 0 !important; */
}

.ETF6-bg {
    background-image: url('../images/border-right-down.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    padding-top: 35px;
    padding-bottom: 70px;
}

.ETF5-bg {
    background-image: url('../images/border-right-up.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 60px;
    /* padding: 150px 0 !important; */
}

.ETF7-bg {
    background-image: url('../images/border-left-down.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    padding-top: 35px;
    padding: 70px 0 90px 0 !important;
}

.ETF4-img {
    height: 375px;
    margin-top: 60px;
}

.ETF6-img {
    height: 50px;
    margin-bottom: 10px;
}

.EFT6-Cont {
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-top: 25px;
    height: 290px;
}

.tx-intro {
    font-size: 25px;
    font-weight: 500;
}

.intro-eight {
    padding-top: 20px;
    background-color: #fff !important;
    padding-bottom: 60px;
}

.boxes-intro-eight {
    border-radius: 10px;
    padding: 60px 30px 30px 30px !important;

    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border: 1px solid hwb(240deg 91% 6%);

}

.intr-mp {
    margin-top: 10px;
}

.bg-intro {
    background-image: url("../images/border-right-down.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 730px;


}

.for-lf {
    margin-left: 40px;
    margin-top: 30px;
}

.mr-for {
    margin-top: 40px;
}

.mr-forex-six {
    margin-top: 30px;
    margin-bottom: 30px;
}

.bg-intro-one {
    background-image: url("../images/border-right-up.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 450px;

}

.mr-for-seven {
    margin-top: 120px;
}

.share-four-align {
    margin-top: 70px;
}

.share-four-bg {
    background-color: #f3e8e5;
    padding-bottom: 100px;
}

.share-cont-align {
    margin-top: 50px;
}

.share-one-img {
    padding: 10px;
}

.share-two-img {
    padding: 10px;
}

.share-three-img {
    padding: 10px;
}

.share-four-img {
    padding: 10px;
}

.share-five-align {
    margin-top: 40px;
    margin-bottom: 30px;
}

.sharefive-cont-align {
    margin: 0px 0px 30px 0px;
    padding-top: 10%;
}

.share-card-two-align {
    margin-top: 20px;
}

.ipo-bg {
    background-image: url("../images/ipo-border-bg.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 700px;
}

.about5-one-head {
    padding-top: 140px;
    color: #d80027;
}

.About5-cont1 {
    height: 320px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 28px;
    margin-bottom: 25px;
}

.about5-links {
    text-decoration: none;
    cursor: pointer;
    color: #d80027;
}

.about5-links:hover {
    color: #000 !important;
}

.container-shrink {
    padding: 0 11%;
    margin-top: 80px;
    margin-bottom: 40px;
}

.about5-two-bg {
    padding-top: 20px;
}

.about5-submit {
    background-color: #d80027;
    color: #fff;
    font-size: 13px;
    padding: 12px 15px;
}

.about5-five-submit {
    background-color: #d80027;
    color: #fff;
    font-size: 13px;
    padding: 10px 25px;
    margin-top: 20px;
}

.about5-submit:hover {
    background-color: #000;
    color: #fff !important;
}

.about-upload {
    background-color: #d9d9d9;
    color: #000;
}

.about-upload:hover {
    background-color: #d9d9d9;
    color: #000;
}

.about5-cont4 {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 35px 25px;
}

.about5-four-bg {
    padding-top: 20px;
    padding-bottom: 60px;
}

.about5-one-img {
    height: 100px;
}

.About5-four-img {
    height: 370px;
    margin-bottom: 10px;
}

.about8-two-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
}

.about8-two-img {
    height: 430px;
    position: relative;
    left: 5%;
}

.about8-two-cont {
    padding: 1% 0;
}

.about8-two-head {
    font-weight: 600;
    font-size: 40px;
}

.about-three-img {
    height: 210px;
    margin-bottom: 10px;
    margin-bottom: 55px;
}

.About8-three-cont {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 35px 45px;
}

.About8-three-cont2 {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 35px 45px;
    height: 680px;
}

.explore-txt2 {
    color: #635af0;
    text-decoration: none;
    cursor: pointer;
    margin-right: 8px;
}

.explore-txt2:hover {
    color: #d80027;
}

.about8-ul {
    position: relative;
    margin-bottom: 15%;
}

.about4-cont {
    background-color: #f4f4f5;
    border-radius: 10px;
    padding: 9% 5%;
}

.about4-row {
    padding: 15% 0 !important;
}

.about8-four-img {
    height: 320px;
}

.about8-four-cont {
    padding: 12% 0 !important;
}

.about8-three-bg {
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../images/about-trade-bg2.svg");
    padding-bottom: 30px;
}

.about8-four-bg {
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../images/about-mt-bg.svg");
    padding-top: 30px;
    padding-bottom: 30px;
}

.about6-one-bg {
    padding: 1% 0 1% 0;
    background-color: #f3e8e5;
}

.about6-two-bg {
    padding-top: 45px;
    padding-bottom: 40px;
}

.about6-two-cont {
    border-radius: 10px;
    border: 1.5px solid #635af0;
    padding-top: .8%;
}

.about6-a-tag {
    color: #635af0;
    text-decoration: none;
    cursor: pointer;
}

.about6-a-tag:hover {
    color: #d80027;
}

.about6-three-bg {
    padding-bottom: 10px;
}

.about6-three-cont {
    background-color: #f4f4f5;
    border-radius: 10px;
    padding: 4% 6%;
    margin-bottom: 50px;
}

.about6-three-a {
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

.about6-three-a:hover {
    color: #000;
}

.about6-three-ico {
    color: #635af0;
    margin-right: 5px;
    height: 20px !important;
    padding-bottom: 2px !important;
}

.about6-three-ico-stay {
    color: #635af0;
    margin-right: 5px;
    height: 17px !important;
    padding-bottom: 2px !important;
}

.about6-three-ico2 {
    color: #635af0;
    margin-right: 5px;
    /* height: 20px !important; */
    padding-bottom: 2px !important;
}

.about6-three-col {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: 10px 15px;
    padding: 3px 0px 3px 0px;
    text-align: center;
}

.about6-four-cont {
    padding-top: 13%;
}

.about6-five-img {
    height: 450px;
}

.about6-five-bg {
    padding-top: 35px;
    padding-bottom: 30px;
}

.about6-five-cont {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 10px;
    margin-bottom: 15px;
    padding-top: 1%;
}

.about6-five-ico {
    color: #d80027;
    margin-right: 5px;
}

.about6-six-cont {
    border-radius: 10px;
    border: 1.5px solid #d80027;
    padding-top: .8%;
}

.ab-fo-one {
    margin-top: 70px;
    background-color: #f3e8e5;
}

.ab-tx-si {
    font-size: 45px;
    font-weight: 650;
    color: #c2223d;
    margin-top: 80px;
}

.abt-tx-p-si {
    margin-top: 20px;
    margin-bottom: 69px;
}

.ab-fo-two {
    padding-top: 30px;
    background-color: #fff;
}

.about-head {
    margin-top: 50px;
}

.about-para {
    margin-top: 30px;
}

.about-para-one {
    margin-top: 15px;
}

.about-para-two {
    margin-left: 80px;
}

.blu-clo {
    color: #8182da;
    text-decoration: none;
    cursor: pointer;
}

.abt-tx-p-si-thre {
    margin-top: 20px;
    margin-bottom: 250px;
}

.bg-about-gr {
    background-color: #f4f4f5;
    padding: 80px 50px 80px 50px;
    border-radius: 10px;
    position: relative;
    bottom: 70px;
}

.ab-three-two {
    background-color: #fff;

}

.bg-wh-two {
    background-color: #fff;
    border: 2px solid hwb(240deg 91% 6%);
    border-radius: 10px;
    padding: 6px 10px 6px 10px;
    font-weight: 500;
    cursor: pointer;

}

.mr-about-two {
    margin-top: 30px;
}

.bg-white-two {
    padding-top: 30px !important;
    background-color: #fff !important;
    padding-bottom: 40px !important;
}

.bg-white-two-stay {
    padding-top: 60px !important;
    background-color: #fff !important;
    padding-bottom: 40px !important;
}

.size-about-se {
    height: 45px !important;
    background-color: #fff;
    border-radius: 5px;
    width: 550px;
    padding-top: 12px;
    margin-left: 45px;
}

.ic-se-si {
    margin-left: 10px;
    margin-right: 20px;
    color: #000 !important;
}

.mar-about-seven {
    margin-top: 50px;
}

.bg-wh-two-abt {
    background-color: #fff;
    border: 2px solid hwb(240deg 91% 6%);
    border-radius: 10px;
    padding: 12px 105px 12px 105px;
    font-weight: 500;
    cursor: pointer;

}

.mar-about-seven-two {
    margin-top: 10px;
}

.bg-wh-two-abt-one {
    background-color: #fff;
    border: 2px solid hwb(240deg 91% 6%);
    border-radius: 10px;
    padding: 12px 60px 12px 60px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 100px;
}

.tr-pl-sec-one-abt {
    background-position: cover;
    background-size: 100%;
    margin-top: 45px;
    background-repeat: no-repeat;
    background-image: url("../images/about-one-bg.svg");
    height: 700px;
}

.about-ph-one-img {
    width: 600px;
    margin-top: -50px;
}

.mr-abt-one-pag {
    margin-bottom: 100px;
}

.bg-col-abt-ro {
    background-color: #ffebec;
    border-radius: 20px;
}

.mar-abt-one-ro {
    margin-top: 240px !important;
    padding-left: 50px;
}

.mar-abt-one-ro-two {
    margin-top: 80px !important;
}

.abt-our-part-one {
    width: 500px;
}

.mar-abt-one-ro-sec {
    margin-top: 200px !important;
    padding-left: 50px;
    margin-bottom: 150px;
}

.Abt-five {
    padding-top: 80px;
    background-color: #fff !important;
}

.about-ph-one-img-2 {
    width: 550px;
    margin-top: -30px;
}

.bor-red-abt {
    border: 2px solid #c2223d;
    padding: 30px 20px 50px 50px;
    border-radius: 20px;

}

.mar-abt-la {
    margin-top: 40px;
}

.Abt-six {
    padding-top: 80px;
    background-color: #fff !important;
    padding-bottom: 120px;
}

.about-two-align {
    margin-top: 120px;
}

.abt-three-align {
    margin-top: 30px;
}

.abt-four-align {
    margin-top: 30px;
}

.red-border {
    border: 2px solid #C2223D;
    border-radius: 8px;
    padding: 20px;
}

.table-align-one {
    line-height: 40px;
}

.table-align-one td {
    font-size: 14px;
}

.table-align-two {
    line-height: 40px;
}

.table-align-two td {
    font-size: 14px;
}

.table-align-three {
    line-height: 40px;
}

.table-align-three td {
    font-size: 14px;
}

.table-align-four {
    line-height: 40px;
}

.table-align-four td {
    font-size: 14px;
}

.abt-tick-img {
    height: 20px;
}

.table-align-six {
    line-height: 40px;
}

.table-align-six td {
    font-size: 14px;
}

.table-align-seven {
    line-height: 40px;
}

.table-align-seven td {
    font-size: 14px;
}

.abt-table-align-one,
.abt-table-align-two,
.abt-table-align-three,
.abt-table-align-four,
.abt-table-align-five,
.abt-table-align-six,
.abt-table-align-seven {
    margin-top: 40px;
}

.abt-table-align-eight {
    margin-top: 40px;
    margin-bottom: 120px;
}

.copier-tx-mr {
    margin-top: 30px;

}

.copier-tx-p {

    margin-top: 10px;

}

.cmpy-img {
    height: 50px;
    margin-top: 210px;
}

.copier-ro-mr {
    margin-top: 70px;
}

.step2-img {
    margin-right: 130px;
    height: 160px;
    margin-top: 20px;
}

.step3-img {

    height: 120px;
    margin-top: 50px;
}

.copier-tx-mr-1 {
    margin-top: 60px;

}

.copier-tx-mr-2 {
    margin-top: 52px;

}

.copier-tx-mr-3 {
    margin-top: 65px;

}

.op2 {
    margin-top: 20px;
}

.op3 {
    margin-top: 30px;
}

.copi-acc {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 20px !important;
    font-weight: 550;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

.copi-fou-tx {
    margin-bottom: 70px;
    font-size: 25px;
}

.to2 {
    margin-top: 10px;
}

.to3 {
    margin-top: 75px;
}

.to4 {
    margin-top: 80px;
}

.copi-tx-si-h-1 {
    margin-top: 40px;
}

.copi-tx-si-h-1-1 {
    margin-top: 35px;
}

.copi-tx-si-h-2 {
    margin-top: 45px;
}

.copi-tx-si-h-3 {
    margin-top: 35px;
}

.copi-tx-si-h-3-3 {
    margin-top: 47px;
}


.copier-tx-mr-3-1 {
    margin-top: 20px;
}

.copier-tx-mr-3-2 {
    margin-top: 20px;
}

.copier-tx-mr-3-3 {
    margin-top: 20px;
}

.two-copier {
    margin-top: 70px;
}

.copier-bg {
    background-color: #f6f7f8;
    padding-top: 60px;
    padding-bottom: 60px;
}

#faq-copy .accordion-button {
    background-color: #f6f7f8 !important;
    padding: 30px;
    box-shadow: none !important;
}

#faq-copy .accordion-body {
    background-color: #f6f7f8 !important;
}

#faq-copy .accordion-item {
    border: none !important;
    border-bottom: 1px solid #73737384 !important;
    border-radius: 0px !important;
}

.accordion-button:not(.collapsed) {
    color: #d80027 !important;
}

.master-img1 {
    height: 60px;
}

.master-img2 {
    height: 300px;
}

.copier-six-bg {
    padding-top: 40px;
    padding-bottom: 20px;
}

.copier-six-btn {
    border: 1.5px solid #d80027;
    border-radius: 7px;
    background-color: #fff;
    color: #d80027;
    margin-top: 30px;
    padding: 10px 65px;
    font-weight: 600;
}

.copier-six-btn:hover {
    background-color: #d80027;
    color: #fff;
    border: 1.5px solid #d80027;
}

.copier-six-btn:focus {
    box-shadow: none;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background-color: #d80027 !important;
    border-color: 1.5px solid #d80027 !important;
}

.btn:hover {
    background-color: #a8011f !important;
    color: #fff;
}

.copier-five-cont {
    padding: 10% 0;
}

.copier-five-ul {
    list-style-type: none;
}

.copier-five-ul {
    margin-top: 20px;
    margin-bottom: 20px;
}


#copier-five-list li::before {
    content: "•";
    color: #d80027;
    display: inline-block;
    width: 1em;
    margin-left: -1em
}

#faq-copy .accordion-button::after {
    background-image: url("../images/plus-icon.svg");
}

#faq-copy .accordion-button:not(.collapsed)::after {
    background-image: url("../images/minus-icon.svg");
}

.copier-one-align {
    margin-top: 65px;
}

.copier-one-bg {
    background-image: url("../images/bg-copy-trade-copier.png");
    background-size: 100%;
    height: 440px;
    background-repeat: no-repeat;
}

.master-one-bg {
    background-image: url("../images/master-bg1.png");
    background-size: 100%;
    height: 440px;
    background-repeat: no-repeat;
}

.copier-para-align {
    margin-top: 40px;
    padding: 20px;
}

.cpy-link {
    text-decoration: none;
}

.cpy-btn {
    background-color: #C2223D;
    border-color: #C2223D;
}

.cpy-btn2 {
    background-color: #C2223D;
    border-color: #C2223D;
    margin-bottom: 10px;
}

.cpy-btn:hover,
.cpy-btn2:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.copier-two-bg {
    background-image: url("../images/bg-copy-trade-master3x.png");
    background-size: 100%;
    height: 83px;
    background-repeat: no-repeat;
}

.copier-bg-border {
    border: 2px solid #000;
    border-radius: 5px;
}

.copier-two-align {
    margin-top: 40px;
}

.copier-text-one {
    line-height: 40px;
}

.cpy-link2 {
    text-decoration: none;
    color: #C2223D;
    font-size: 12px;
}

.cpy-link2:hover {
    color: #fff;
}

.pamm-one-bg {
    padding-top: 4%;
    padding-bottom: 10%;
}

.pamm-one-cont {
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pamm-one-row {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 2%;
    padding-right: 2%;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.pamm-one-head {
    color: #FF5848;
    cursor: pointer;
    text-decoration: none;
}

.pamm-one-head-nonactive {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}


.pamm-one-head:hover {
    color: #FF5848;
}

.pamm-one-cont2 {
    padding-top: 2%;
    padding-bottom: 3%;
}

.pamm-one-cont3 {
    padding-top: 3%;
    padding-bottom: 5%;
}

.pamm-one-head2 {
    color: #000;
}

.pamm-one-head3 {
    color: #455a64;
}

.pamm-one-head4 {
    color: #C2223D;
}

.pamm-one-head5 {
    color: #000;
}

.pamm-one-text {
    color: #455a64 !important;
}

.pamm-one-img {
    height: 70px;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: transform .2s;
}

.pamm-one-img:hover {
    transform: scale(1.2);
}

.pamm-cont-circle {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 50%;
    padding: 20px;
    margin-bottom: 15px;
    color: #455a64 !important;
}

.pamm-one-btn2 {
    font-size: 15px;
    font-weight: 600;
    color: #455a64;
    max-width: 250px !important;
    border-radius: 20px;
    padding: 10px 0;
    margin-bottom: 10px;
    background-color: transparent;
    border: 1.5px solid #455a64;
    width: 200px !important;
}

.pamm-one-btn {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    max-width: 250px !important;
    border-radius: 20px;
    padding: 10px 0;
    margin-bottom: 10px;
    background-color: #455a64;
    border: 1.5px solid #455a64;
    width: 200px !important;
}


.pamm-one-btn:hover {
    background-color: #FF5848;
    border: 1.5px solid #ff5848;
}

.pamm-one-btn2:hover {
    background-color: #FF5848;
    border: 1.5px solid #FF5848;
}

.pamm-hero {
    padding-top: 8%;
    background-color: #fff;
}

.txt-cl-pam {
    color: #ff5848;
    font-size: 65px;
    margin-top: 50px;
}

.pamm-cont-circle:hover {
    color: #ff5848 !important;
    cursor: pointer;
}

.ib-two {
    padding-top: 100px;
    padding-bottom: 50px;
    /* box-shadow: inset 0px -18px 21px -26px rgba(0, 0, 0, 0.25); */
}

.ib-two-h {
    margin-top: 38px;
}

.ib-two-h-2 {
    margin-top: 28px;
}

.ib-two-h-3 {
    margin-top: 38px;
}

.ib-two-p-1 {
    margin-top: 70px;
}

.ib-line {
    border: 2px solid;
    margin-top: 50px;
}

.ib-three-h {
    font-weight: 550;
}

.ib-three-p-1 {
    margin-top: 20px;
}

.ib-three-h-1 {
    margin-top: 100px;
}

.ib-one-bg {
    background-color: #fff;
}

.ib-two-bg {
    background-image: url("../images/pamm-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 600px;
    padding-top: 10%;
    padding-bottom: 0%;
    box-shadow: inset 0px -18px 21px -26px rgba(0, 0, 0, 0.25);
}

.ib-head-align {
    margin-top: 150px;
}

.ib-one-head {
    color: #c2223d;
}

.ib-one-text {
    line-height: 60px;
}

.ib-trade-btn {
    background-color: #C2223D;
    border-color: #C2223D;
}

.ib-trade-btn:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.ib-three-bg {
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 50% 100%;
    background-repeat: no-repeat;
}

.ib-one-cont {
    padding: 3% !important;
}

.ib-one-cont2 {
    padding-top: 25px;
    padding-bottom: 25px;
    border-right: 2px solid #000;
}

#ib-one-level {
    opacity: .4;
}

#ib-one-level:hover {
    opacity: 1;
    cursor: pointer;
}

.ib-one-row {
    padding-top: 3%;
    padding-bottom: 3%;
}

.ib-one-img {
    height: 450px;
}

.button-rounded {
    background-color: #FF5848;
    color: #fff !important;
    border-color: #ff5848;
    margin-top: 30px;
    margin-bottom: 20px;
}

.button-rounded:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.button-rounded-hyper {
    text-decoration: none;
    color: #fff;
}

.button-rounded-hyper:hover {
    text-decoration: none;
    color: #000;
}

.ib-four-btn {
    background-color: #FF5848;
    color: #fff !important;
    border-color: #ff5848;
}

.ib-four-btn:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.ib-four-hyper {
    text-decoration: none;
    color: #fff;
}

.ib-four-hyper:hover {
    text-decoration: none;
    color: #000;
}

.ib-seven-btn {
    background-color: #FF5848;
    color: #fff;
    border-color: #ff5848;
}

.ib-seven-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.ib-seven-hyper {
    text-decoration: none;
    color: #fff;
}

.ib-seven-hyper:hover {
    color: #000;
}

.ib-eight-btn {
    background-color: #c2223d;
    color: #fff;
    border-color: #c2223d;
    font-size: 14px;
}

.ib-eight-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.ib-eight-card-design {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 3px;
    border: none;
}

/* .ib-eight-hyper {
  text-decoration: none;
  color: #fff;
}

.ib-eight-hyper:hover {
  text-decoration: none;
  color: #000;
} */

.white-txt {
    color: #fff;
}

.ib-four-bg {
    padding-top: 3%;
    padding-bottom: 3%;
}

.ib-four-bg-two {
    padding-top: 2%;
    padding-bottom: 2%;
}

.ib-four-align-two {
    margin-top: 30px;
}

.ib-four-row-align {
    margin-top: 70px;
}

.ib-eight-cont {
    max-width: 960px;
    margin-top: 40px;
}

.trade-btn-hyper {
    text-decoration: none;
    color: #fff;
}

.trade-btn-hyper:hover {
    color: #000;
}

/* .section-wrap {
  box-shadow: inset 0px -18px 21px -26px rgba(0, 0, 0, 0.25);
  height: 20px;
} */
.ib-five-align {
    padding-top: 80px;
    background-color: #f3e8e5;
}

.text-section {
    margin-top: 146px;
}

.des-two {
    margin-top: 75px;
}

.des-three {
    margin-top: 83px;
}

.des-one,
.des-two,
.des-three,
.des-four {
    font-size: 14px;
}

.ib-six-align {
    padding-top: 130px;
    background-color: #f3e8e5;
}

.ib-text-section {
    margin-top: 210px;
}

.ib-des-two {
    margin-top: 125px;
}

.ib-des-one,
.ib-des-two {
    font-size: 14px;
}

.affiliate-sec {
    padding-top: 30px;
    background-color: #fff;
}

.description {
    line-height: 50px;
}

.star-color {
    color: #C2223D;
}

.aff-des {
    font-size: 16px;
    margin-top: 30px;
}

.ib-section-six .points-section ul {
    list-style: none;
    padding-left: 0;
}

.ib-row-align {
    margin-top: 40px;
}

.ib-four-card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    height: 300px;
}

.ib-four-card2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 10px;
    padding: 5px;
}

.ib-four-row {
    border-bottom: 1px solid #737373;
}

.ib-four-card-head {
    padding: 3% 0 2% 1% !important;
}

.total-income {
    font-size: 14px;
}

.tr-pl-sec-one-pamm {
    background-position: cover;
    background-size: 100%;
    margin-top: 45px;
    background-repeat: no-repeat;
    background-image: url("../images/pamm-bg.svg");
    height: 700px;
}

.mr-pamm-cont {
    padding-top: 150px;
}

.mr-pamm-cont-1 {
    padding-top: 150px;
}

.trpla-sing-pamm {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-bottom: 30px;
    padding: 12px 30px 12px 30px;

}

.abt-tx-p-si-pamm {
    margin-top: 20px;
    margin-bottom: 50px;
}

.tx-si-pamm-h {
    margin-top: 20px;
    margin-left: -40px;
}

.tx-si-pamm-p {
    margin-top: 70px;
    margin-left: -40px;
}

.tx-bt-pamm {
    margin-left: -50px;
}

.spa-pamm {
    margin-top: 65px;
    margin-bottom: 40px;
}

.text-base {
    margin-bottom: 50px;
}

.tr-pl-sec-two-pamm {
    background-position: cover;
    background-size: 106%;
    margin-top: 45px;
    background-repeat: no-repeat;
    background-image: url("../images/pamm-bg-two.svg");
    margin-bottom: -125px;
    background-position-y: -253px;
    background-position-x: -14px;
    margin-bottom: 45px;
}

.mobile-pamm {
    height: 76%;
    /* margin-left: 120px; */
    margin-top: -86px;
}

.mar-tp-pamm-three {
    margin-top: -90px;
}

.mar-tp-pamm-three-p {
    margin-top: 40px;
}

.pr-1 {
    margin-top: 40px;
}

.fw-pamm-1 {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    margin-top: 10px;
    font-size: 20px;
    margin-top: 6px;
    margin-left: -10px !important;
}

.fw-pamm-1:hover {
    color: #303030;
}

.fw-pamm-2 {

    font-weight: 500;
    margin-top: 10px;
    font-size: 20px;
    margin-top: -25PX;
    margin-left: 50px;
}

.pamm-ico-2 {
    margin-top: 10px;
}

.fw-pamm-3 {

    font-weight: 500;
    margin-top: 10px;
    font-size: 20px;
    margin-top: -25PX;
    margin-left: 50px;
}

.pamm-btn {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 60px;
    margin-bottom: 30px;
    padding: 10px 40px 10px 40px;
}

.pamm-btn:hover {
    background-color: #000;
    color: #fff;
}

.pamm-btn-2 {
    background-color: #fff;
    color: #c2223d;
    border: 1px solid #c2223d;
    font-size: 14px !important;
    margin-top: 60px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

.pamm-btn-2:hover {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
}

.ib-three-align {
    padding-top: 30px;
}

.ib-seven-bg {
    padding-top: 30px;
    padding-bottom: 30px;
}

.ib-card-cont {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ib-form-card-head {
    line-height: 40px;
    border-bottom: 1px solid #455a64;
}

.navbar-expand-lg .navbar-nav {
    align-items: center !important;
}

.regul-cont {
    padding-top: 20px;
}

.text-base1 {
    margin-top: 16px;
}

.text-base2 {
    margin-bottom: 4px;
}

.bg-co {
    background-color: #f3e8e5 !important;
}

.mr-tp-forex {
    margin-top: -130px !important;
}

.new-con {
    padding: 13px 800px 13px 15px;
    border: 2px solid #c2223d;
    border-radius: 10px;

}

.con-new-1 {
    margin-top: 100px !important;
    margin-left: 60px !important;
}

.text-ba {
    margin-top: 12px;
}



.pad-btn {
    padding-top: 27.5%;
}

.com-sec2-pad {
    padding-bottom: 96px;
}

.platform1 {
    background-image: url('../images/trading-bg-two.svg');
    background-size: 77%;
    background-repeat: no-repeat;
    background-position-y: 21px;
}

.platform2 {
    background-image: url('../images/trading-bg-one.svg');
    background-size: 69%;
    background-repeat: no-repeat;
    background-position-y: 217%;
    background-position-x: 100%;
}

.mobile2-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: left;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    margin-top: 0px;
}

.ourplat-flx {
    flex-direction: column;
    align-items: end;
}

.ourplat-flx-st {
    flex-direction: column;
    align-items: start;
}

/* .tes-etf
{
  margin-left: 10px;
} */


.tx-cl-re {
    color: #e00024;
    font-size: 45px;
    font-weight: 600;
}

.forex-bg-4 {
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 40px;
    margin-top: 33px;
}

.tx-p-re {
    color: #fff;
    margin-top: 18px;
}

.lap-re-img {
    width: 850px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.mr-re-tp {
    margin-top: 40px;
    margin-bottom: 40px;
}

.bg-forex-five {
    background-color: #ffedf0;
    padding-top: 50px;
    padding-bottom: 50px;
}

.bg-forex-five-new {
    background-color: #ffedf0;
    padding-top: 85px;
    padding-bottom: 60px;
}

.bg-copier-five {
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 50px;
}

.img-1 {
    height: 110px;
}

.mr-tp-fx-5 {
    margin-top: 30px;
    font-weight: 600;
}

.mr-p-fx-5 {
    margin-top: 25px;
}

.mt5-three-cont {
    padding-top: 10px;
    padding-bottom: 50px;
}

.forex-re-7 {
    margin-top: 80px;
    margin-bottom: 50px;
}

.tx-cl-re-8 {
    color: #e00024;
    font-weight: 550;
}

.tx-cl-red-8 {
    color: #e00024;
    font-weight: 550;
    margin-top: 10px;
    margin-bottom: 50px;
}

.expand-txt {
    color: #d9072d;
    cursor: pointer;
    text-decoration: underline;


}

.expand-txt:hover {
    color: #000;
}

.txt-move {
    text-align: right;
    margin-top: 25px;
}

.forex-bg-cl9 {
    background: linear-gradient(to bottom, #FDC0BE 0%, #eac2c2 100%);
    border-radius: 15px;
    padding: 60px 30px 60px 30px;
}

.forex-bg-9 {

    margin-bottom: 60px;
}

.per-re-img {
    height: 450px;
    margin-top: 60px;
}

.bg-ni-wh {
    padding: 15px 20px 60px 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;

}

#name-nine {

    padding: 15px 0px 15px 10px !important;
}

.bt-nine {
    cursor: pointer;
    background-color: #d80027;
    color: #fff;
    text-decoration: none;
    padding: 20px 170px 20px 170px;

}

.bt-nine:hover {
    color: #000;
}

.bt-nine-mr {
    margin-top: 40px;
}

.forex-11-bg {
    padding-top: 60px;
    background-image: #f8f8f8 !important;
    background-repeat: no-repeat;
    margin-top: 0px;
}

.forex-11-head {
    color: #e00024;
}

.forex4-bg-1 {
    background-color: #ffff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.forex4-bg-2 {
    background-color: #f9e8e7;
    /* padding-top: 30px;
  padding-bottom: 30px; */
}

.forex4-flag {
    /* height: 100px !important; */
    width: 100px;
    margin-bottom: 10px;
}

.forex4-cont {
    padding: 6% 5%;
}

.hero-img-forex {
    margin-top: 100px;
}

.forex-bg {
    background-position: cover;
    background-size: 100%;
    margin-top: 65px !important;
    background-repeat: no-repeat;
    background-image: url("../images/forex-bg.png");
    height: 700px;

}

.copier1-bg {
    background-position: cover;
    background-size: 100%;
    margin-top: 65px;
    /* padding-top: 105px; */
    background-color: #f3e8e5;
    background-repeat: no-repeat;
    background-image: url("../images/copier-bg.png");
    /* background-image: url(http://localhost:3000/static/media/copier-bg.d17e6b230cce7d66e08b.png); */
    height: 700px;
    display: flex;
    align-items: center;
}

.tx-cl-re-1 {
    color: #e00024;
    font-size: 50px;
    font-weight: 650;
    margin-top: 105px;
}

.forex-our-btn {
    margin-top: 30px;
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}


.forex-our-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.Shares-bg-5 {
    background-color: #FDC0BE;
    padding-bottom: 60px;
    padding-top: 60px;
}

.ctrader-img-re {
    height: 400px;

}

.sh-tx-h {
    color: #e00024;
    font-size: 50px;
    font-weight: 600;
    margin-top: 80px;
}

.share-our-btn {
    margin-top: 60px !important;
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}


.share-our-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.bull-img {
    width: 40px;
    padding-bottom: 13px;
}

.blog-fex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-sp {
    background-color: #fff;
    padding: 24px 31px;
    border-radius: 6px;
}

.card-sp1 {
    background-color: #fff;
    padding: 38.4px 31px;
    border-radius: 6px;
}

.card-out {
    padding: 0px 70px;
}

.blog-fex p {
    color: #b3b3b3;
    font-weight: 500;
    font-size: 14px;
}

.card-1-cont {
    color: #606060;
    font-size: 16px;
    padding-top: 52px;
}

.bloger-sect h4,
.analyst-sect h4,
.insider-sect h4,
.hedge-funds-sect h4 {
    font-size: 27px;
    font-weight: 600;
    padding-bottom: 40px;
    padding-top: 64px;
}

.red-prog {
    width: 33%;
    position: relative;
    top: 6px;
    height: 6px;
}

.red-sold-txt {
    color: red;
    font-size: 16px;
    padding-top: 20px;
    text-align: left;
    font-weight: 400;
}

.blue-sold-txt {
    color: #259cff;
    font-size: 16px;
    padding-top: 20px;
    text-align: right;
    font-weight: 400;
}

.blog-fex-end {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.red-bx {
    color: red;
    padding: 10px 23px;
    background-color: #fff2f3;
    font-size: 17px;
    font-weight: 500;
    border-radius: 6px;
}

.trn-txt {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 16px;
}

.trend-cont {
    color: #b3b3b3;
    font-weight: 500;
    font-size: 17px !important;
    margin: 0;
}

.card-sp2 {
    background-color: #fff;
    padding: 29.4px 31px;
    border-radius: 6px;
}

.bloger-sect,
.hedge-funds-sect,
.insider-sect,
.analyst-sect {
    padding: 0px 44px;
}

.section4-head {
    color: #d80027;
    padding-bottom: 37px;
    font-weight: 600;
}

.no-pad {
    padding: 0px;
}

.share-bg-3 {
    background-color: #000;
    padding-top: 50px;
    padding-bottom: 40px;
}

.commod-bg-4 {
    background-color: #f8f8f8;
}

.rect-img {
    width: 26%;
    position: relative;
    bottom: 21%;
    left: 80%;
    z-index: -1;
}

.indx {
    z-index: 1;
}

.forec2-bg-out {
    background-image: url("../images/trading-bg-two_preview_rev_1.png");
    background-repeat: no-repeat;
    background-size: 100% 175%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.commod-4-txt-h {
    color: #e00024;
    font-size: 45px;
    font-weight: 600;
    margin-top: 60px;

}

.commod-4-txt-hh {
    color: #e00024;
    font-size: 45px;
    font-weight: 600;
    margin-top: 32px;
}

.commod-4-txt-p {
    margin-top: 35px;
    font-size: 20px;
    font-weight: 500;
}

.commod-4-txt-pp {
    margin-top: 32px;
    font-size: 17px;
    font-weight: 500;
}

.bg-wh-comd {
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.comd-our-btn {

    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 55px 8px 55px;
}


.comd-our-btn:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.comd2-our-btn {

    background-color: #fff;
    color: #C2223D;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}


.comd2-our-btn:hover {

    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;

}

.comd-our-btn-nw-mob {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 50px 8px 50px;
}

.comd-our-btn-nw-mob:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

.comd2-our-btn-nw-mob {
    background-color: #fff;
    color: #C2223D;
    border-color: #C2223D;
    padding: 8px 17px 8px 17px;
}

.comd2-our-btn-nw-mob:hover {

    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;

}

.mr-comd-tp {
    margin-top: 40px;
}

.mr-comd-bt {
    margin-bottom: 90px;
    margin-top: 20px;
}

.m23 {
    margin-left: 30px;
}

.tx-cl-re-1-p {
    margin-top: 20px;
}

.t-tw {
    width: 1200px !important;
}

.txt-etf-h-1 {
    font-weight: 600;
    margin-top: 60px;
}

.mr-etf-1 {
    margin-top: 60px;
    margin-bottom: 80px;
}

.mr-etf-2 {
    margin-top: 60px;
    margin-bottom: 80px;
}

.txt-etf-h {
    margin-bottom: 30px;
}

.txt-etf-p {
    margin-top: 40px;
}

.etf-ser {
    height: 300px;
    margin-top: 50px;
}

.para-wit {
    max-width: 400px !important;
}

.trde-intro {
    padding: 26px 55px;
}

.commod-pad {
    padding-top: 70px;
    padding-bottom: 20px;
}

.mobile-role {
    padding-top: 100px;
}

.txt-cop-h1 {
    font-size: 45px;
    font-weight: 600;
    color: #d80027;
    /* margin-top: 80px; */
}

.txt-cop-p {
    margin-top: 30px;
    line-height: 30px;
}

.mr-p-cp-5 {
    color: #fff;
    margin-top: 25px;
}

.copier-six-mr {
    margin-top: 30px;
    margin-bottom: 40px;
}

.cop-mr-h {
    font-weight: 600;
    font-size: 45px;
    color: #d80027;
    margin-top: 80px;
    margin-bottom: 90px;
}

.about5-links3 {
    text-decoration: none;
    cursor: pointer;
    color: #d80027;
}

.about5-links3:hover {
    color: #fff !important;
}


@media (min-width: 1200px) {
    #fo-bg-re {
        height: 558px;
    }

    #co-bg-re {
        height: 605px;
    }

    .bt-nine {
        padding: 20px 130px 20px 130px;
    }

}

@media (max-width:540px) {

    .forex-bg {
        background-image: none !important;
        height: auto !important;
    }

    .copier1-bg {
        background-image: none !important;
        height: auto !important;
    }

    .img-1 {
        width: 50px;
        height: 50px;
    }

    .mr-p-fx-5 {
        font-size: 12px;
    }

    .mr-p-cp-5 {
        font-size: 12px;
    }

    .tx-cl-re-1 {
        margin-top: -1px;
        margin-top: 30px;
        font-size: 40px;
    }

    .hero-img-forex {
        margin-top: -1px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .bt-nine {
        padding: 20px 65px 20px 65px;
    }

    .per-re-img {
        height: 325px;
        margin-top: 50px;
    }

    .forex3-cont {
        overflow-x: scroll !important;
    }

    .bg-intro {
        background-image: none !important;
        height: auto;
    }

    .card-out {
        padding: 0px 0px;
    }

    .bloger-sect h4,
    .analyst-sect h4,
    .insider-sect h4,
    .hedge-funds-sect h4 {
        font-size: 25px;
        font-weight: 600;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    .mobile-role {
        /* overflow-x: scroll !important; */
        padding: 14px 10px;
    }

    .rect-img {
        display: none;
    }

    html {
        overflow-x: hidden;
    }

    .bloger-sect,
    .hedge-funds-sect,
    .insider-sect,
    .analyst-sect {
        padding: 0px 14px;
    }

    .comd-our-btn {
        padding: 5px 33px 5px 33px;
    }

    .comd2-our-btn {
        padding: 5px 12px 5px 12px !important;
    }

    .shares2-bg {
        background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
        padding-bottom: 170px !important;
    }

    .forec2-bg-out {
        background-size: 100% 181%;
    }

    .forex-bg-4 {
        margin-top: 0px;
    }

    .etf-ser {
        height: 170px;
        margin-top: -50px;
    }

    .trde-intro {
        padding: 18px 17px;
    }

    .commod-4-txt-hh {
        color: #e00024;
        font-size: 38px;
        font-weight: 600;
        margin-top: 0px;
    }
}

@media (min-width:1500px) and (max-width:1890px) {

    .forex-bg {
        height: 670px !important;
    }

    .copier1-bg {
        height: 750px !important;
    }

    .rect-img {
        width: 28%;
        position: relative;
        bottom: 24%;
        left: 78%;
        z-index: -1;
    }

    .m23 {
        margin-left: 85px;
    }

    .bt-nine {
        padding: 20px 170px 20px 170px;
    }
}

@media (min-width:1900px) {

    .forex-bg {
        height: 800px !important;
    }

    .copier1-bg {
        height: 897px !important;
        background-size: 100% 100%;
    }

    .rect-img {
        width: 33%;
        bottom: 27%;
        left: 72%;
    }

    .card-out {
        padding: 0px 196px;
    }

    .card-sp2 {
        background-color: #fff;
        padding: 29px 31px;
        border-radius: 6px;
    }

    .bt-nine {
        padding: 20px 170px 20px 170px;
    }
}

.sec-one-cpy-0 {
    /* margin-top: 100px; */
    /* margin-bottom: 60px; */
    background-image: url("../images/trading-bg-one.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-size: 130%;
    padding-bottom: 100px;
}


.sec-one-cpy-3 {
    margin-top: 100px;
    /* margin-bottom: 60px; */
    background-image: url("../images/trading-bg-two.svg");
    background-repeat: no-repeat;
    background-position-x: -18px;
    background-size: 165%;
    padding-bottom: 30px;
}




.fw-bo-copy-head-cpy {
    text-align: left;
    color: #d80027;
    padding-bottom: 62px;
    font-weight: 600;
    font-size: 50px;
}

.cpy-s2-lft {
    margin-top: 20px;
}

.copier-tx-mr-cp1 {
    margin-top: 32px;
    font-size: 41px;
    font-weight: 600;
    margin-bottom: 25px;
}

.copier-tx-p-cpy {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 44px;
}


.our-btn-cpy {
    margin-top: 40px;
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}


.cpy-trd-box {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: 0px;
}

.no-pad1 {
    padding: 0px;
}

.cpy-1234 {
    margin-top: 83px;
}

.cpy-trd-box1 {
    padding: 16px 15px;
}

.bp-box-s1 {
    padding-right: 27px;
    padding-left: 15px;
}

.personname {
    font-weight: 600;
}

.no-mrg {
    margin-bottom: 0px;
}

.cpy-trade-pers-grn {
    color: #6a963a;
    margin-right: 8px;
}

.create-accc {
    background-color: #c2223d;
    text-decoration: none;
    cursor: pointer;
    font-size: 8px;
    color: #fff;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;


}

.create-accc:hover {
    background-color: #d80027;
    color: #fff;
}

.sec-one-cpy-2 {
    margin-top: 100px;
    /* margin-bottom: 60px; */
    background-image: url("../images/trading-bg-two.svg");
    background-repeat: no-repeat;
    background-position-x: -8px;
    background-size: 130%;
    padding-bottom: 100px;
}

.marg-ttt {
    padding-top: 100px;
}

.cmpy-img-copy-2 {
    width: 75%;
}

.cpy-s2-lft {
    padding: 1px 46px;
}

.bnt-bnt1 {
    text-align: center;
}

.txt-cp-eg {
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 40px;
}

.list-cp-eg {
    font-size: 22px;
    font-weight: 450;
    padding-left: 0;
    list-style-type: disc;
}

.mr-tp-li-cp {
    margin-top: 22px;
    font-size: 20px;
    font-weight: 400;
}

.mb-copier-img {
    width: 450px;
}

.cmpy-img-copy {
    height: 76%;
}

.fw-bo-copy-head-mstr {
    text-align: left;
    color: #d80027;
    padding: 45px 2px;
    font-weight: 600;
    font-size: 50px;
}

.master-two-bg {
    border-left: 30px solid #C2223D;
}

.master-three-bg {
    margin-top: 100px;
    margin-bottom: 100px;
    border-right: 30px solid #c2223d;
}

.master-four-bg {
    border-left: 30px solid #c2223d;
    padding: 50px 0px;
}

.copier-tx-p-mstr {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.copier-tx-mr-mstr {
    margin-top: -13px;
    font-size: 41px;
    font-weight: 500;
    margin-bottom: 25px;
}

.copier-tx-mr-mstr-s3 {
    margin-top: 43px;
    font-size: 41px;
    font-weight: 500;
    margin-bottom: 40px;
}

.mstr-1234 {
    margin-top: 30px;
}

.fw-bo-copy-head1-mstr {
    text-align: left;
    color: #d80027;
    padding: 0px 0px;
    font-weight: 600;
    font-size: 47px;
}

.bp-box-s3-mstr {
    padding-left: 10px;
}

.tx-mas-re-1 {
    color: #e00024;
    font-size: 45px;
    font-weight: 600;
    margin-top: 100px;
    margin-bottom: 50px;
}

.bg-master-six {
    background-color: #000;
    padding-top: 75px;
    padding-bottom: 60px;
}

.mb-master-img {
    height: 470px;
    margin-top: 50px;
}

.our-pl-re-tx-h {
    margin-top: 60px;
}

.our-pl-re-tx-p {
    margin-top: 40px;
}

.our-btn-re-lr {
    margin-top: 50px;
}

.our-pla-sys-re {
    width: 600px;
}

/* our platform section desktop code starts */
.head-our-platf {
    color: #d80027;
    padding: 45px 2px;
    font-weight: 600;
    font-size: 50px;
}

.our-plat-cont-p {
    margin-top: 10px;
    font-size: 16.5px;
    font-weight: 400;
    line-height: 30px;
}

.appl-btn {
    height: 101%;
    padding-right: 2px;
}

.ply-btn-our-pltdf {
    padding-left: 2px;
}

.ourplatform-sec-2 {
    padding-bottom: 10%;
}

.our-platf-sec2-img {
    width: 93%;
    padding: 35px 0px;
}

.ourplatf-rit-mob-bg-new {
    padding-top: 50px;
    padding-bottom: 11px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    /* margin-top: -118px; */
}

.ourplatf-mobile2-img {
    height: 452px;
    /* position: relative; */
    right: 8.5%;
    margin-bottom: 32px;
}

.dowload-cont-ourplatf {
    padding: 5% 34% 5% 0%;
}

.ourplatf-s3-head {
    margin-top: 32px;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
}

.ourplat-s3-cont {
    margin-top: 10px;
    font-size: 16.5px;
    font-weight: 400;
    line-height: 30px;
}

/* our platform section desktop code Ends */
/* Ctrade section desktop code Starts */
.lrn-mre-ctrade {
    color: #d80027;
    font-weight: 600;
}

.mt5-card-ctrade {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 30px;
    height: 360px;
}

.s1-ctr-crd {
    padding-bottom: 46px;
}

.s2-ctr-crd {
    padding-bottom: 27px;
}

.s3-ctr-crd {
    padding-bottom: 28px;
}

.s4-ctr-crd {
    padding-bottom: 64px;
}

.mt5-two-img-ctrade {
    height: 40px;
    margin-bottom: 25px;
    margin-top: 15px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    padding: 6px;
    border-radius: 10px;
}

.head-our-ctrade {
    color: #d80027;
    /* padding: 45px 2px; */
    font-weight: 600;
    font-size: 50px;
    padding-bottom: 45px;
}

.mt5-two-bg-ctrade {
    background-color: #fff;
    padding-bottom: 100px;
}

.ctrade-card-titl1,
.ctrade-card-titl2,
.ctrade-card-titl3,
.ctrade-card-titl4 {
    color: #000;
    font-weight: 600;
}

.ctrade-card-titl2 {
    padding-right: 70px;
}

.ctrade-card-titl3 {
    padding-right: 80px;
}

.ctrade-card-titl4 {
    padding-right: 109px;
}

/* Ctrade section desktop code Ends */

#btn-group-new .btn-toolbar {
    display: block !important;
}

.mobile-one-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-color: #303030;
    background-size: 100%;
}

.mobile-one-bg-new {
    padding-top: 60px;
    padding-bottom: 70px;
    background-color: #303030;
    background-size: 100%;
}

.app-btn-new {
    height: 35px !important;
    margin-top: 20px;
}

.app-btn2-new {
    height: 35px !important;
    margin-top: 20px;
}

.dowload-cont2 {
    padding: 30% 0%;
}

.img-border {
    border: 1px solid #d80027;
    position: relative;
    right: 55px;
}

.mobile2-img-new {
    height: 404px;
}

.mobile-three-bg {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 43% 100%;
    height: 490px;
    background-image: url('../../src/images/mob-trade-imgs.png');
}

.mobile-three-1bg {
    /* font-size: 31px; */
    padding-left: 60px;
}

.mobile-three-2bg {
    /* font-size: 31px; */
    padding-top: 20%;
}

.mobile-three-3bg {
    /* font-size: 31px; */
    padding-top: 10%;
    padding-left: 60px;
}

.mobile5-bg2 {
    background-color: #c2223d !important;
}







/* .sec-one-cpy-0 {
    margin-top: 25px;
    text-align: center;
    background-position-x: 100%;
    background-size: 285%;
    padding-bottom: 0px;
} */




.fw-bo-copy-head-cpy {
    padding-bottom: 0px;
}

/* .sec-one-cpy-3 {
    margin-top: 100px;
    background-size: 404%;
    padding-bottom: 0px;
    background-position-x: -10px;
    background-position-y: 102px;
} */



.cpy-s2-lft {
    padding: 1px 0px 1px 115px;
}


.our-btn-cpy {
    margin-top: 20px;
}



/* .sec-one-cpy-0 {
    margin-top: 25px;
    text-align: center;
    background-position-x: 100%;
    background-size: 285%;
    padding-bottom: 0px;
} */


.mob-alg-cpy-2 {
    padding: 1px 30px;
    line-height: 30px;
    font-size: 17px;
}




.cpy-1234 {
    margin-top: 83px;
    padding: 0px 26px;
}


.btn-cpy-trd-pad {
    padding: 0px 12px;
}


/* .sec-one-cpy-2 {
    background-size: 348%;
    background-position-y: 200%;
} */


.marg-ttt {
    padding-top: 0px;
}


.cpy-s2-lft {
    margin-top: 20px;
}



.mstr-two-img-lft {
    justify-content: center !important;
}


.fw-bo-copy-head1-mstr {
    font-size: 31px;
}

/* our platform section mobile view code starts */
.head-our-platf {
    padding: 10px 2px;
    font-size: 39px;
}

.our-plat-cont-p {
    font-size: 14px;
    padding: 1px 20px;
    line-height: 20px;
}

.our-platf-sec2-img {
    width: 100%;
    padding: 20px 0px;
}

.ourplatf-rit-mob-bg-new {
    padding-top: 50px;
    padding-bottom: 11px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    /* margin-top: -118px; */
}

.ourplatf-mobile2-img {
    height: 452px;
    /* position: relative; */
    right: 8.5%;
    margin-bottom: 32px;
}

.dowload-cont-ourplatf {
    padding: 5% 34% 5% 0%;
}

.ourplatf-s3-head {
    margin-top: 32px;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
}

.ourplat-s3-cont {
    margin-top: 10px;
    font-size: 16.5px;
    font-weight: 400;
    line-height: 30px;
}

/* our platform section desktop code Ends */
/* Ctrade section desktop code Starts */
.lrn-mre-ctrade {
    color: #d80027;
    font-weight: 600;
}

.mt5-card-ctrade {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 30px;
    height: 360px;
}

/* .s1-ctr-crd {
    padding-bottom: 46px;
}

.s2-ctr-crd {
    padding-bottom: 27px;
}

.s3-ctr-crd {
    padding-bottom: 28px;
}

.s4-ctr-crd {
    padding-bottom: 64px;
} */

.mt5-two-img-ctrade {
    height: 40px;
    margin-bottom: 25px;
    margin-top: 15px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    padding: 6px;
    border-radius: 10px;
}

.head-our-ctrade {
    color: #d80027;
    /* padding: 45px 2px; */
    font-weight: 600;
    font-size: 50px;
    padding-bottom: 45px;
}

.mt5-two-bg-ctrade {
    background-color: #fff;
    padding-bottom: 100px;
}

.ctrade-card-titl1,
.ctrade-card-titl2,
.ctrade-card-titl3,
.ctrade-card-titl4 {
    color: #000;
    font-weight: 600;
}

.ctrade-card-titl2 {
    padding-right: 70px;
}

.ctrade-card-titl3 {
    padding-right: 80px;
}

.ctrade-card-titl4 {
    padding-right: 109px;
}

/* Ctrade section desktop code Ends */

#btn-group-new .btn-toolbar {
    display: block !important;
}

.mobile-one-bg {
    padding-top: 60px;
    padding-bottom: 35px;
    background-color: #303030;
    background-size: 100%;
}

.mobile-one-bg-new {
    padding-top: 60px;
    padding-bottom: 70px;
    background-color: #303030;
    background-size: 100%;
}

.app-btn-new {
    height: 35px !important;
    margin-top: 20px;
}

.app-btn2-new {
    height: 35px !important;
    margin-top: 20px;
}

.dowload-cont2 {
    padding: 30% 0%;
}

.img-border {
    border: 1px solid #d80027;
    position: relative;
    right: 55px;
}

/* .mobile2-img-new {
    height: 430px;
    position: relative;
    left: 24px;
    bottom: 16px;
} */

/* .mobile-three-bg {
    padding-bottom: 35px;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
} */

.mobile-three-1bg {
    /* font-size: 31px; */
    padding-left: 50px;
}






.ctrade-s3-head {

    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
}

.ctrade-s3-cont {
    margin-top: 20px;
    font-size: 16.5px;
    font-weight: 400;

}

/* .tx-si-c-trade-h {

    margin-left: -20px;
} */

.tx-si-c-trade-p {
    margin-top: 30px;
    /* margin-left: -20px; */
}

/* .tx-bt-c-trade {
    margin-left: -30px;
} */

.c-trade-btn {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

.mob-trade-img-1 {
    height: 450px;
    margin-top: 30px;
    margin-left: 35%;
}

.mob-trade-img-2 {
    height: 450px;
    margin-top: 30px;
    margin-left: 35%;
}

.min-ght-ctrade {
    height: 205px;
}

.mr-re-trade-2 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.bg-trading-two {
    background-color: #000;
    padding-top: 60px;
    padding-bottom: 50px;
}

.mr-tp-trading-5 {
    margin-top: 30px;
    color: #fff;
}

.trading-txt-6 {
    color: #fff;
    margin-top: 60px;
}

.mr-trading-seven {
    margin-top: 40px;
}

/* Trading page desktop css starts */
.tradingpg-s3-bg {
    background-image: url("../images/trading-bg-two.svg");
    background-size: 100%;
    padding: 5px 0px 50px 0px;
    background-repeat: no-repeat;
    background-position-y: 80%;
}

.mobile-three-1bg-tradings {
    padding-left: 90px;
    padding-top: 3%;
}

.trading-pg-head {
    margin-top: 32px;
    font-size: 39px;
    font-weight: 500;
    margin-bottom: 40px;
}

.trading-ul-s {
    padding: 0;
    list-style-type: none;
}

.bobile-txt-trading {
    margin-top: 10px;
    font-size: 19px;
}

.tradingpg-s4-bg {
    background-image: url("../images/trading-bg-one.svg");
    background-size: 100%;
    padding: 5px 0px 50px 0px;
    background-repeat: no-repeat;
    background-position-y: 80%;
}

.tardingpg-s4-mob-img {
    width: 400px;
}

.tradingpg-mob-img {
    width: 400px;
}

.trading-ul-sec5-s {
    padding: 0;
    list-style-type: none;
    padding-top: 80px;
}

/* Trading page desktop css Ends */

/*trading page Desktop new css Starts*/
.our-btn-new-trading {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 50px;
    margin-top: 20px;
}

.our-btn-new-chat {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px;
    margin-right: 4px;
    margin-top: 20px;
}

.our-btn-new-call {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 50px;
    margin-left: 4px;
    margin-top: 20px;
}

/*trading page Desktop new css Ends*/
/*abt-why page Desktop new css Starts*/
.why-wins-sec3-cont {
    padding-right: 41%;
    color: #808080;
    padding-top: 3%;
}

.lap-re-img-why-winsor {
    width: 850px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 56px;
}

.abut-eiht {
    background-image: url("../images/forex-bg-two.svg");
    background-repeat: no-repeat;
    background-position-x: -34px;
    background-position-y: 55px;
    background-size: 76% 84%;
}

.about5-four-bg-abt-why {
    padding-top: 20px;
    padding-bottom: 30px;
    background-color: #f2e8e5;
    margin-bottom: 60px;
}

.lap-re-img-why-winsor-ctrd-lap {
    width: 60%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.img-contai {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.why-winsor-btn {
    background-color: #C2223D;
    color: #fff;
    border-color: #C2223D;
    padding: 8px 25px 8px 25px;
}

.lap-re-img-why-winsor-ctrd-mob2 {
    width: 30%;
    margin-top: 15px;
    margin-bottom: 15px;
    /* padding-top: 56px; */
}

.padding-why-abt-row {
    padding: 40px 0px;
}

/*abt-why page Desktop new css Ends*/

.regul-cont-re {
    padding-top: 60px;
    padding-bottom: 20px;
}

.copier1-bg-new {
    background-position: cover;
    background-size: 100% 100%;
    margin-top: 45px;
    padding-top: 105px;
    background-color: #f3e8e5;
    background-repeat: no-repeat;
    background-image: url("../images/copier-bg.png");
    height: 700px;

}


.table {
    --bs-table-hover-bg: #f3e8e5 !important;
    --bs-table-border-color: #f3e8e5 !important;
    --bs-table-hover-color: #000 !important;
    --bs-table-striped-bg: #f3e8e5 !important;
    border: 3px solid #f3e8e5 !important;

}

.table-bordered>:not(caption)>*>* {
    border-width: 0px 3px !important;
}

.abt-txt-red {
    color: #d80027;
    font-weight: 520;
}

.abt-txt-mr-re {
    margin-top: 20px;
    margin-bottom: 30px;
}

.abt-compla-re {
    color: #d80027;
    font-weight: 550;

}

.blu-clo-re {
    color: #d80027;
    text-decoration: none;
    cursor: pointer;
    font-weight: 550;
}

.mr-re-abt-com {
    margin-bottom: 180px;
}

/*Staying Online page Desktop new css Starts*/
.stying-pg-hero-cont {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding-top: 40px;
}

.head-styinf-title {
    color: #d80027;
    padding: 45px 2px;
    font-weight: 600;
    font-size: 39px;
}

.fardulant-list-sty {
    list-style-type: none;
    padding-left: 0px;
}

.fardu-ul {
    display: flex;
}

.officialweb-list-sty {
    list-style-type: none;
    padding-left: 0px;
}

.officialweb-list-sty li {
    padding-bottom: 20px;
}

.about6-a-tag-1 {
    color: #d80027;
    text-decoration: none;
    cursor: pointer;
}

.about6-a-tag-1:hover {
    color: #d80027;
}

.about6-three-cont-1 {
    background-color: #f3d6d8;
    border-radius: 10px;
    padding: 4% 6% 1% 6%;
    margin-bottom: 10px;
}

.head-styinf-title1 {
    color: #d80027;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 39px;
}

.stying-btn {
    background-color: #d80027;
    color: #fff;
    border-radius: 0;
    border-color: #d80027;
    padding: 12px 30px 12px 30px;
    margin: 0px 0px;
    width: 200px;
    cursor: pointer;
    margin-left: 15px;
}

.off-commuin {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

.safety-online-pg-bg {
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}

.about6-four-cont-1 {
    padding-top: 13%;
    padding-left: 15px;
    margin-left: 8%;
}

.about8-two-img-1 {
    /* position: relative; */
    left: 5%;
    width: 75%;
    padding: 30px 40px;
}

.about6-five-bg-11 {
    padding-top: 60px;
    padding-bottom: 30px;
}

.fraud-padd {
    padding-left: 4%;
}

/*Staying Online page Desktop new css Ends*/

.txt-cop-h1-new {
    font-size: 45px;
    font-weight: 600;
    color: #d80027;
    margin-top: 0px;
    margin-bottom: 15px;
}

#faq-icon {
    background-color: #ecdfdc;
    border: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    /* padding-left: 10px; */
    padding-right: 0px;
}

#faq-form::placeholder {
    color: #000 !important;
}

#faq-form {
    background-color: #ecdfdc;
    border: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height: 50px;
}

#faq-form:focus {
    box-shadow: none;
}

.faq1-cont {
    padding: 6% 0;
}

.faq-2-text {
    text-decoration: none !important;
    cursor: pointer !important;
    background-color: #f3e8e5;
    padding: 20px 20px;
    width: 250px !important;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
}

.faq-2-text:hover {
    background-color: #a8011f !important;
    color: #fff !important;
}

.faq2-bg {
    padding-top: 70px;
    padding-bottom: 50px;
}

.faq3-bg {
    background-color: #f4d6d8 !important;
    height: 440px;
}

.faq3-btn {
    background-color: #bf1b37;
    width: 100%;
    border: none;
    height: 60px !important;
}

#faq3-form .faq-form {
    height: 60px !important;
    border-radius: 5px !important;
    border: none !important;
    margin-bottom: 25px !important;
}

#faq3-form .faq-form::placeholder {
    font-size: 11px;
    font-weight: 500;
    padding-left: 15px;
    opacity: 0.7;
}

#faq3-form .faq-form:focus {
    box-shadow: none !important;
    border: none !important;
}

.mobile5-img-mobile-new {
    height: 500px;
    width: 260px;
}

.faq3-inmg-new {
    position: relative;
    bottom: 90px;
    /* top: 630% !important; */
}

.faq-image-align {
    justify-content: end;
}

.contact-form-bg {
    background-color: #f4d6d8;
    padding: 2% 4% !important;
}

#Support-form .input-design {
    border: none;
}

#Support-form .input-design:focus {
    box-shadow: none !important;
}

/* .mobile5-bottom-cut
{
   margin-bottom: -300px;
} */

/*new Contact support Desktop view css Starts*/
.contact-support-bg {
    background-color: #f2e8e5;
    padding: 45px 20px;
    margin-bottom: 6%;
}

.contact-suupot-sec-2bg {
    background-color: #000;
    padding: 6% 10px;
}

.about5-two-bg-13 {
    padding-top: 5%;
}

/*new Contact support Desktop view css Ends*/

.leg-2-text {
    text-decoration: none !important;
    cursor: pointer !important;
    background-color: #f3e8e5;
    padding: 20px 20px;
    width: 350px !important;
    font-size: 17px;
    font-weight: 550;
    margin-top: 20px;
    margin-bottom: 20px;
}

.leg-2-text:hover {
    background-color: #a8011f !important;
    color: #fff !important;
}

.mr-re-abt-three {
    margin-top: 40px;
    margin-bottom: 15px;
}

.txt-abt-three {
    font-size: 40px;
}

.leg-3-text {
    text-decoration: none !important;
    cursor: pointer !important;
    background-color: #fff;
    padding: 10px 10px;
    width: 100% !important;
    font-size: 17px;
    font-weight: 550;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #e00324;
}

.leg-3-text:hover {
    background-color: #ecdfdc !important;
    color: #e00324;
}

.bg-about-three {
    background-color: #ffedf0;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.red-bg-phone-1 {
    height: 430px;
}

.why-winsor-btn {
    background-color: #d80027;
    color: #fff;
    font-size: 13px;
    padding: 10px 25px;
    /* margin-top: 20px; */
}

.cpy-s2-lft00 {
    padding: 1px 115px 1px 25px;
}

.cp-tre-cont-p {
    text-align: center;
    font-size: 18px;
}

.cpy-s222 {
    padding-left: 50px;
}

.cmpy-img-copy-mstr {
    height: 70%;
}

.cpy-s2-lfttttt {
    padding-left: 50px;
}

.mobile-cont-space-new {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: -20px;
}

.fluide-cpy-pg {
    padding-left: 100px;
}

.t-b-s {
    margin-bottom: 40px;
}

.forex4-flag-n1 {
    /* height: 100px !important; */
    width: 82px;
    margin-bottom: 10px;
}

.blue-prog {
    height: 6px;
}

.hiht-et-2 {
    height: 760px;
}

.etf-three-mr {
    margin-top: 160px !important;
}

.txt-rd-clo {
    color: #e82849;
}

.fnt-wght {
    font-weight: 500;
}

.table-align-three-new td {
    font-size: 14px;
    line-height: 25px;
    vertical-align: middle;
}

.bg-lft-1 {
    background-image: url("../images/border-left-up.svg");
    background-repeat: no-repeat;
    background-position-x: -20px;
    background-blend-mode: overlay;
    background-position-y: top;
    margin-bottom: 100px;
}

.bg-rit-2 {
    background-image: url("../images/border-right-down.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-blend-mode: overlay;
    background-position-y: bottom;
    background-size: inherit;
}

.tx-si-pamm-p-new1 {
    margin-top: 70px;
    margin-left: -40px;
    max-width: 595px;
}

.mobile-cont-space-spacenew1 {
    padding-bottom: 4%;
    padding-top: 5%;
}

.mobile-cont-space-new-32 {
    padding-top: 5%;
    padding-bottom: 0%;
    margin-bottom: -90px;
}

.crd-img1,
.crd-img2,
.crd-img3 {
    width: 234px;
    position: absolute;
}

.crd-img3 {
    top: 144%;
    left: 32%;
    box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
}


.crd-img1 {
    top: 120%;
    left: 8%;
    box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
}

.crd-img2 {
    top: 166%;
    left: 8%;
    box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
}

.open-acc-img {
    width: 52%;
    margin-top: -127px;
    z-index: -1;
}

.dir-flx {
    flex-direction: column;
    align-items: center;
}

.common-mb {
    padding-bottom: 5%;
}

.mr-new-change {
    margin-top: 180px;
}

.tx-cl-re-bobtr {
    color: #e00024;
    font-size: 46px;
    font-weight: 600;
    padding-bottom: 4%;
    padding-top: 1%;
}

.ourplat-left-bg-new {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 45% 100%;
    height: auto;
    /* background-image: url('../../src/images/ctradeimg.png'); */
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}

.pamm1-bg-new {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 48% 100%;
    height: auto;
    /* background-image: url('../../src/images/pamm1-img.png'); */
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}

.saftysix-bg-new {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    height: auto;
    /* background-image: url('../../src/images/pamm1-img.png'); */
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}


.trad-plat2-bg-new {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 40% 100%;
    height: auto;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}

.stay-bg {
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    height: auto;
    background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
}

.shares2-bg-nw {
    background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
    padding-bottom: 40px;
    margin-bottom: -20px;
}

.ib-two-bg-nw {
    background-image: url("../images/pamm-bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 600px;
    padding-top: 10%;
    padding-bottom: 0%;
    box-shadow: inset 0px -18px 21px -26px rgba(0, 0, 0, 0.25);
}

.rect-img-nw {
    width: 26%;
    position: relative;
    top: 68%;
    left: -11%;
    z-index: -1;
    height: 35%;
}

.cmpy-img-copy-2-scr {
    width: 100%;
}

.mstr-bold {
    font-weight: 600;
}

#sec-four-mob-vw {
    display: none;
}

#sec-four-desk-ve {
    display: unset;
}

.img-bg-hme {
    background: linear-gradient(0deg, rgba(194, 34, 61, 1) 0%, rgba(244, 212, 211, 1) 58%, rgba(255, 255, 255, 1) 100%);
    border-bottom-left-radius: 44px;
    border-bottom-right-radius: 44px;
    max-width: 344px;
    overflow-y: hidden !important;
}

.eursd {
    /* max-width: 86px;
    height: 61px; */
    position: relative;
    top: 38.5%;
    left: 116.7%;
    z-index: 1;
    /* margin-bottom: 20px; */
}

.grph {
    height: 40px;
}

.buy {
    height: 32px;
}

.sell {
    height: 32px;
}

.first-slide1 {
    height: 42px;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.first-slide2 {
    height: 42px;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

.first-slide3 {
    height: 42px;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}

hr.new1 {
    border-top: 1.5px solid #d5d5d5;
    width: 90%;
}

.pamm-btn-2-nw {
    background-color: #fff;
    color: #c2223d;
    border: 1px solid #c2223d;
    font-size: 14px !important;
    padding: 10px 20px 10px 20px;
}

.pamm-btn-nw {
    background-color: #c2223d;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    padding: 10px 40px 10px 40px;
}

.mobile-pamm-nw {
    height: 120%;
    margin-left: -40px;
    margin-top: -86px;
}

.trading-promotion-five .reward-calculate-text {
    border-radius: 10px;
    box-shadow: 0 0 6px #00000042;
}

.reward-calculate-text .title {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #00000042;
    padding: 20px;
    color: #000000;
}

.trading-promotion-five .reward-calculate-text form {
    padding: 20px;
}

.form-group {
    margin-bottom: 1rem;
}

.trading-promotion-five .reward-total {
    text-align: center;
    padding-bottom: 20px;
}

.trading-promotion-five .reward-total ul li {
    color: #263238;
    display: inline-block;
    padding: 10px;
    padding-bottom: 20px;
}

.trading-promotion-five .reward-total .total-title {
    font-size: 14px;
}

.trading-promotion-five .reward-total .digit {
    font-size: 25px;
    font-weight: 600;
}

.trading-promotion-five .reward-total ul li {
    color: #263238;
    display: inline-block;
    padding: 10px;
    padding-bottom: 20px;
}

.trading-promotion-five .reward-total .become-partner {
    background-color: #FF5848;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    vertical-align: super;
}

.range-slider input[type="range"] {
    background: #FF0000;
    appearance: none;
    width: 100%;
    height: 3px;
    outline: none;
    -webkit-appearance: none;
    margin-top: 29px;
}

.reward-calculate-text .account-progress-text {
    font-size: 15px;
    font-weight: normal;
    color: #000000;
}

.reward-calculate-text .range-two-per {
    width: 15%;
    display: inline-block;
    color: #7E7E7E;
    font-weight: 500;
}

.reward-calculate-text .range-two-text input {
    width: 121%;
}

.range-slider {
    padding: 5px 0px 13px 10px;
    margin: 0 0 20px 0;
    position: relative;
}

.reward-calculate-text .range-two-text {
    width: 70%;
    display: inline-block;
}

.range-slider .range-value {
    color: #263238;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
    height: 30px;
    width: 50px;
    transform: translateY(50%);
    position: absolute;
    bottom: 76%;
    right: 0;
    border-radius: 5px;
    box-shadow: 0 0 3px #00000042;
}

.range-two-text input,
.range-one input {
    accent-color: rgb(255, 88, 72) !important;
}

.trading-promotion-five .reward-total {
    text-align: center;
    padding-bottom: 20px;
}

.trading-promotion-five .reward-total ul {
    border-radius: 5px;
    box-shadow: 0 0 6px #00000042;
    background-color: #F8F8F8;
    margin: 0 45px;
}

.trading-promotion-five .reward-total .become-partner {
    background-color: #FF5848;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    vertical-align: super;
}

.reward-total ul a {
    text-decoration: none;
}

.ib-six-align3 {
    padding-top: 130px;
    padding-bottom: 130px;
}

/* fourth sec js */
.sec-four-crd1 {
    height: 9%;
    position: relative;
    top: -29%;
    left: -13%;
}

.sec-four-crd2 {
    height: 9%;
    position: relative;
    top: -15%;
    left: 6%;
}

.sec-four-crd3 {
    height: 12%;
    position: relative;
    top: -53%;
    left: 60%;
    border-radius: 6px;
}

.hero-fourth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sec-four-imgmob {
    position: relative;
    left: 22%;
    height: 335px;
    margin-top: 40px;
}

.imges-grp {
    position: relative;
    top: 7%;
    left: 4%;
}

.frm-grp select {
    background-image: url('../images/arrow-down-sign-to-navigate.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 10px;
}

.frm-grp select:focus {
    box-shadow: none !important;
}

.frm-grp .account-type {
    padding-bottom: 7px;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    animation-iteration-count: infinite;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.formErrors p {
    color: red;
    font-weight: 600;
    text-align: center;
    padding-bottom: 2%;
}


@media (max-width:540px) {

    .mr-pamm-cont-1 {
        padding-top: 0px;
    }

    .tes-mr {
        margin-top: 30px !important;
    }

    #tes-mr-1 {
        margin-top: 25px !important;
    }

    .abut-eiht {
        background-image: none !important;
    }

    .mobile-pamm-nw {
        height: 120%;
        margin-left: 0px;
        margin-top: -28px;
        margin-bottom: 0%;
    }

    .mar-tp-pamm-three {
        margin-top: 26px;
    }

    .sec-one-cpy-0 {
        margin-top: 25px;
        text-align: center;
        background-position-x: 100%;
        background-size: 285%;
        padding-bottom: 0px;
    }

    .mobile-three-bg {
        background-position: center !important;
        background-size: cover !important;
        padding-top: 30px;
    }

    .bnt-bnt {
        text-align: center;
    }


    .fw-bo-copy-head-cpy {
        padding-bottom: 0px;
    }

    .sec-one-cpy-3 {
        margin-top: 100px;
        background-size: 404%;
        padding-bottom: 0px;
        background-position-x: -10px;
        background-position-y: 102px;
    }

    .cpy-s2-lft {
        padding: 1px 46px;
    }

    .copier-tx-mr-cp1 {
        font-size: 28px;
        text-align: center;
    }

    .copier-tx-p-cpy {
        font-size: 17px;
        text-align: center;
    }

    .our-btn-cpy {
        margin-top: 20px;
    }

    .cmpy-img-copy {
        width: 90%;
        padding-top: 58px;
    }

    .sec-one-cpy-0 {
        margin-top: 25px;
        text-align: center;
        background-position-x: 100%;
        background-size: 285%;
        padding-bottom: 0px;
    }

    .mob-alg-cpy-2 {
        padding: 1px 30px;
        line-height: 30px;
        font-size: 17px;
    }

    .cpy-1234 {
        margin-top: 83px;
        padding: 0px 0px;
    }

    .btn-cpy-trd-pad {
        padding: 0px 13px;
    }

    .sec-one-cpy-2 {
        background-size: 348%;
        background-position-y: 200%;
    }

    .marg-ttt {
        padding-top: 0px;
    }

    .cmpy-img-copy-2 {
        width: 90%;
    }

    .cpy-s2-lft {
        margin-top: 20px;
    }

    .copier-tx-mr-mstr {
        font-size: 31px;
        text-align: center;
    }

    .mstr-two-img-lft {
        justify-content: center !important;
        margin-top: 70px;
    }

    .copier-tx-mr-mstr-s3 {
        margin-top: 32px;
        font-size: 31px;
        font-weight: 500;
        margin-bottom: 50px;
        text-align: center;
    }

    .fw-bo-copy-head1-mstr {
        font-size: 31px;
    }

    /* our platform section mobile view code starts */
    .head-our-platf {
        padding: 10px 2px;
        font-size: 39px;
    }

    .our-plat-cont-p {
        font-size: 14px;
        padding: 1px 20px;
        line-height: 20px;
    }

    .our-platf-sec2-img {
        width: 100%;
        padding: 20px 0px;
    }

    .ourplatf-rit-mob-bg-new {
        padding-top: 50px;
        padding-bottom: 11px;
        background-image: linear-gradient(36deg, rgba(243, 29, 49, 1) 0%, rgba(244, 35, 52, 1) 25%, rgba(252, 96, 88, 1) 58%, rgba(255, 120, 102, 1) 100%);
        background-position: right;
        background-size: 100% 55%;
        background-repeat: no-repeat;
        /* margin-top: -118px; */
        background-position-y: 123%;
    }

    .ourplatf-mobile2-img {
        height: 379px;
        margin-bottom: 7px;
    }

    .dowload-cont-ourplatf {
        padding: 1px 26px;
        margin-bottom: 27%;
    }

    .ourplatf-s3-head {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 27px;
        text-align: center;
    }

    .ourplat-s3-cont {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }

    .cent-ou7rpltf {
        text-align: center !important;
    }

    /* our platform section mobile view code Ends */

    /* padding-left: 50px;
} */
    .mobile-three-cont {
        padding-top: 100px;
    }

    .tx-si-c-trade-p {
        margin-left: 5px !important;
    }

    .tx-si-c-trade-h {
        margin-left: 5px !important;
    }

    .mob-trade-img-1 {
        margin-left: -5px;
        height: 250px;
        margin-bottom: 10px;
    }

    .mob-trade-img-2 {
        margin-left: -5px;
        height: 250px;
        margin-bottom: 10px;
    }

    .tx-bt-c-trade {
        margin-left: 1px;
    }

    .img-border {
        position: static !important;
        border: none !important;
    }

    .mobile2-img-new {
        position: static !important;
    }

    .dowload-cont2 {
        padding: 10% 0% !important;
    }

    /* Trading page Mobile css starts */
    .tradingpg-mob-img,
    .tardingpg-s4-mob-img {
        width: 70%;
    }

    .tradingpg-s3-bg {
        background-size: 180%;
        background-position-y: 158%;
        padding-bottom: 0px;
    }

    .tradingpg-s4-bg {
        background-size: 234%;
        background-position-y: 24%;
        background-position-x: right;
        padding-bottom: 0px;
    }

    .our-btn-new-chat {
        background-color: #C2223D;
        color: #fff;
        border-color: #C2223D;
        padding: 8px 15px;
        margin-right: 4px;
    }

    .our-btn-new-call {
        background-color: #C2223D;
        color: #fff;
        border-color: #C2223D;
        padding: 8px 40px;
        margin-left: 4px;
    }

    .mobile-three-1bg-tradings {
        padding-left: 31px;
    }

    .tardingpg-s4-mob-img {
        padding-top: 18%;
    }

    .tradingpg-s3-bg-mobile {
        background-size: 180%;
        background-position-y: 134%;
        padding-bottom: 0px;
    }

    .copier1-bg-new {
        height: 550px !important;
        background-image: none;
    }

    .faq3-inmg-new {
        margin-top: 30% !important;
    }

    .faq-image-align {
        justify-content: center !important;
    }

    .faq3-bg {
        height: auto !important;
    }

    /* Trading page Mobile css Ends */
    /*new Why winsorfx mobile view css Starts*/
    .why-wins-sec3-cont {
        padding-right: 0;
        color: #808080;
        padding-top: 3%;
        text-align: left;
    }

    .abut-eiht {
        background-position-y: -113px;
        background-size: 115% 82%;
    }

    .lap-re-img-why-winsor {
        width: 850px;
        margin-top: 0px;
        margin-bottom: 15px;
        padding-top: 0px;
    }

    /*new Why winsorfx mobile view css Ends*/
    /*new stying safely mobile view css Starts*/
    .off-commuin {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .stying-btn {
        margin: 10px 5px;
    }

    .head-styinf-title {
        font-size: 30px;
    }

    .bg-white-two-stay {
        padding-top: 0px !important;
    }

    .safety-online-pg-bg {
        margin-top: 60px;
    }

    .fraud-padd {
        padding-left: 0%;
    }

    /*new stying safely mobile view css Ends*/

    /*new Contact support mobile view css Starts*/

    /*new Contact support mobile view css Ends*/
    .why-winsor-btn {
        margin-bottom: 40px !important;
    }

    .cpy-s2-lft00 {
        padding: 1px 42px;
    }

    .just-cent {
        justify-content: center !important;
    }

    .cpy-s2-lfttttt {
        padding-left: 72px;
    }

    .cmpy-img-copy-mstr {
        height: 90%;
    }

    .fluide-cpy-pg {
        padding-left: 30px;
        padding-right: 22px;
    }

    .t-b-s br {
        margin-bottom: 40px;
        display: none;
    }

    .cop-mr-h {
        font-size: 31px;
    }

    .txt-cop-h1 {
        font-size: 31px;
        margin-top: 80px;
    }

    .tx-mas-re-1 {
        font-size: 26px;
    }

    .fw-bo-copy-head-mstr {
        font-size: 40px;
    }

    #sec-four-mob-vw {
        display: unset;
    }

    #sec-four-desk-ve {
        display: none;
    }

    .crd-img1,
    .crd-img2,
    .crd-img3 {
        width: 188px;
        position: absolute;
    }

    .crd-img1 {
        position: relative;
        top: 30px;
        left: -23%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img2 {
        position: relative;
        top: -95px;
        left: -23%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img3 {
        position: relative;
        top: 100px;
        left: 23%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-grp {
        display: none;
    }

    .trading-promotion-five .reward-total ul {
        padding: 10px 0;
    }

    .range-slider .range-value {
        bottom: 79%;
        font-size: 14px;
    }

    .reward-calculate-text .account-progress-text {
        font-size: 13px !important;
    }

    .trading-promotion-five .reward-calculate-text form {
        padding: 7px 20px;
    }

    .trading-promotion-five .reward-total .digit {
        margin: 0;
    }

    .range-slider input[type="range"] {
        background: #FF0000;
        appearance: none;
        width: 292px;
        height: 3px;
        outline: none;
        -webkit-appearance: none;
    }

    .sec-third-p {
        margin-top: 60px;
    }

    .dnone {
        display: none;
    }

    .bg-wh-comd {
        margin-bottom: 22px;
    }

    .mr-comd-bt {
        margin-top: 0px;
    }

    .no-pad-nw-mob {
        padding: 0;
    }
}

@media (max-width:1367px) and (min-width:1360px) {
    #co-bg-re {
        height: 674px;
    }

    #fo-bg-re {
        height: 595px;
    }

    .ib-two-bg {
        height: 674px;
    }
}

/* For medium devices 12" to 13" */
@media (max-width:1500px) and (min-width:1301px) {
    .ib-two-bg-nw {
        height: 651px;
    }

    .indx {
        position: relative;
        left: -4%;
    }
}

.text-base3 {
    margin-bottom: 30px;
}

@media (min-width:1500px) and (max-width:1890px) {
    .crd-img3 {
        top: 139%;
        left: 32%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img1 {
        top: 116%;
        left: 8%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img2 {
        top: 165%;
        left: 8%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .flex-class-nw {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }

    .ib-two-bg-nw {
        background-size: cover;
        height: 751px;
        padding-top: 4%;
    }

    .ib-head-align {
        margin-top: 0px;
    }
}

@media (min-width: 1900px) {
    /* .ourplat-left-bg-new {
        background-size: 30% 100%;
        height: 500px;
    }

    .pamm1-bg-new {
        background-size: 40% 100%;
        height: 700px;
    }

    .saftysix-bg-new {
        background-size: 35% 100%;
        height: 630px;
    }

    .trad-plat2-bg-new {
        background-size: 35% 100%;
        height: 600px;
    }

    .mobile-three-bg {
        background-position: right;
        background-repeat: no-repeat;
        background-size: 30% 100%;
        height: 530px;
    } */

    .crd-img1 {
        top: 87% !important;
        left: 17%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img3 {
        top: 109% !important;
        left: 36%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img2 {
        top: 135% !important;
        left: 17%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .first-slide1 {
        height: 57px;
    }

    .eursd {
        left: 123.5%;
    }

    .sec-one-p {
        height: 505px;
    }
}

@media(min-width:1200px) and (max-width:1300px) {

    /* .nav-link
    {
        font-size: 12px !important;
    } */
    .nav-links {
        font-size: 12px !important;
    }

    .crd-img1 {
        top: 150%;
    }


    .crd-img3 {
        top: 183%;
    }

    .crd-img2 {
        top: 216%;
    }

    .indx {
        position: relative;
        left: -36px;
    }
}

/* for Monitor */
@media(min-width:1900px) {
    .mob-trade-img-1 {
        padding-left: 30%;
    }

    .mob-trade-img-2 {
        padding-left: 40%;
        padding-bottom: 4%;
    }

    .our-plat-two-img {
        position: absolute;
        padding-left: 20%;
        padding-bottom: 1%;
    }

    .about8-two-img-1 {
        height: 600px;
        width: 600px;
    }

    .cmpy-img-copy {
        height: 61%;
    }

    .master-three-bg {
        height: 600px;
    }

    .master-two-bg {
        height: 600px;
    }

    .cmpy-img-copy-mstr {
        height: 61%;
    }

    .master-sec-2-bg-pad {
        padding-top: 4%;
    }

    .rect-img-nw {
        width: 18%;
        position: relative;
        top: 68%;
        left: -14%;
        z-index: -1;
        height: 35%;
    }

    .moni-indic {
        position: relative;
        left: 16%;
    }
}

/* for Larger devices */
@media(min-width:1501px) and (max-width:1899px) {
    .abut-eiht {
        background-position-y: 23px;
    }

    .mob-trade-img-2 {
        margin-left: 39%;
    }

    .mobile3-img-new {
        padding-left: 32%;
    }

    .ib-one-img {
        margin-left: 4%;
    }

    .our-plat-two-img {
        /* clip-path: circle(); */
        height: 450px;
        padding-left: 18%;
    }
}

/* for medium devices */
@media(min-width:1301px) and (max-width:1499px) {
    .mob-trade-img-1 {
        margin-left: 42%;
    }

    .mob-trade-img-2 {
        margin-left: 43%;
    }

    .mobile3-img-new {
        padding-left: 31.5%;
    }

    .ib-one-img {
        margin-left: 4%;
    }

    .our-plat-two-img {
        position: absolute;
        padding-left: 10%;
        padding-bottom: 1%;
    }

}

#querytype-select.form-select:focus {
    border: none;
}

#querytype-select.form-select:focus:focus {
    box-shadow: none !important;
    border: none;
}

/* option:hover
{
    background-color: #ff5848 !important;
    color: #fff !important;
    transition: 0.4s !important;
} */
/* for Normal devices */
@media(min-width:1200px) and (max-width:1300px) {}

.pamm-img {
    height: 55px;
}

.pamm-card-design {
    border: none !important;
}

.pamm-card-align {
    margin-top: 50px;
}

@media (max-width:1400px) {
    .copier1-bg {
        background-position: cover;
        background-size: 100%;
        margin-top: 65px;
        /* padding-top: 105px; */
        background-color: #f3e8e5;
        background-repeat: no-repeat;
        background-image: url("../images/copier-bg.png");
        /* background-image: url(http://localhost:3000/static/media/copier-bg.d17e6b230cce7d66e08b.png); */
        height: 700px;
        display: flex;
        align-items: center;
        -webkit-background-position: cover;
        -webkit-background-size: 100%;
        -webkit-margin-top: 65px;
        /* padding-top: 105px; */
        -webkit-background-color: #f3e8e5;
        -webkit-background-repeat: no-repeat;
        -webkit-background-image: url("../images/copier-bg.png");
        /* background-image: url(http://localhost:3000/static/media/copier-bg.d17e6b230cce7d66e08b.png); */
        -webkit-height: 700px;
        -webkit-display: flex;
        -webkit-align-items: center;
    }

    .forex-bg {
        background-position: cover;
        background-size: 100%;
        margin-top: 65px;
        background-repeat: no-repeat;
        background-image: url("../images/forex-bg.png");
        height: 700px;
        -webkit-background-position: cover;
        -webkit-background-size: 100%;
        -webkit-margin-top: 65px;
        -webkit-background-repeat: no-repeat;
        -webkit-background-image: url("../images/forex-bg.png");
        -webkit-height: 700px;

    }

    /* .sec-one-p {
        height: 380px;
        margin-top: 30px;
        filter: drop-shadow(0 30px 90px #fa807257);
        -webkit-height: 380px;
        -webkit-margin-top: 30px;
        -webkit-filter: drop-shadow(0 30px 90px #fa807257);
    } */


}

@media(min-width: 1400px) {
    .modal-align {
        padding-top: 10% !important;
    }

    .copier1-bg {
        background-position: cover;
        background-size: 100%;
        margin-top: 65px;
        /* padding-top: 105px; */
        background-color: #f3e8e5;
        background-repeat: no-repeat;
        background-image: url("../images/copier-bg.png");
        /* background-image: url(http://localhost:3000/static/media/copier-bg.d17e6b230cce7d66e08b.png); */
        height: 700px;
        display: flex;
        align-items: center;
        -webkit-background-position: cover;
        -webkit-background-size: 100%;
        -webkit-margin-top: 65px;
        /* padding-top: 105px; */
        -webkit-background-color: #f3e8e5;
        -webkit-background-repeat: no-repeat;
        -webkit-background-image: url("../images/copier-bg.png");
        /* background-image: url(http://localhost:3000/static/media/copier-bg.d17e6b230cce7d66e08b.png); */
        -webkit-height: 700px;
        -webkit-display: flex;
        -webkit-align-items: center;
    }

    .forex-bg {
        background-position: cover;
        background-size: 100%;
        margin-top: 65px;
        background-repeat: no-repeat;
        background-image: url("../images/forex-bg.png");
        height: 700px;
        -webkit-background-position: cover;
        -webkit-background-size: 100%;
        -webkit-margin-top: 65px;
        -webkit-background-repeat: no-repeat;
        -webkit-background-image: url("../images/forex-bg.png");
        -webkit-height: 700px;

    }

    /* .sec-one-p {
        height: 380px;
        margin-top: 30px;
        filter: drop-shadow(0 30px 90px #fa807257);
        -webkit-height: 380px;
        -webkit-margin-top: 30px;
        -webkit-filter: drop-shadow(0 30px 90px #fa807257);
    } */
}


@media (max-width:540px) {

    .tes-mr-bt {
        margin-bottom: 50px;
    }

    .bg-rit-2 {
        height: auto !important;
    }

    .bg-lft-1 {
        height: auto !important;
    }

    .ctrader-img-re {
        margin-top: 50px;
    }

    .etf-three-mr {
        margin-top: 20px !important;
    }

    .ib-two-bg-nw {
        height: auto;
        background-image: none;
    }

    .tes-by {
        margin-bottom: 50px !important;
    }

    .mb-tes {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .sec-four-imgmob {
        /* height: 210px; */
        left: 0;
    }

    .sec-fifth-align {
        margin-top: 120%;
    }

    /* .sec-four-crd1 {
        height: 8%;
        left: -51%;
    }

    .sec-four-crd2 {
        height: 8%;
        left: -30%;
        top: -14%;
    }

    .sec-four-crd3 {
        height: 11%;
        left: 64%;
        top: -53%;
    } */

    .tx-si-pamm-p-new1 {
        margin-left: 0px;
    }

    .pamm1-bg-new {
        background-position: bottom;
        background-size: 100% 40%;
        padding-bottom: 20px;
        padding-top: 0px;
    }

    .tx-bt-pamm {
        margin-left: 0px;
        text-align: center;
    }

    .mt5-sing {
        margin-top: 10px;
        padding: 15px 30px 15px 30px;
    }

    .tr-pl-la-new {
        height: 225px;
    }

    .mobile2-img-new {
        height: 350px;
    }

    .text-btn-center {
        justify-content: center !important;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .show>.nav-link {
        color: #ff5848 !important;
        text-align: center;
    }

    .dropdown-item {
        font-size: 14px;
    }

    .mb-tes-ib {
        margin-top: 50px;
    }

    .hiht-et-2 {
        height: auto;
    }

    .home-img-align {
        display: flex;
        justify-content: center;
    }

    .trad-plat2-bg-new {
        background-size: 100% 60% !important;
        background-position: bottom;
        padding-top: 40px;
        padding-bottom: 10px;
    }

    .our-btn-new {
        margin-top: 5px;
    }

    .txt-etf-h {
        margin-top: -40px;
    }

    .bts-mb-re {
        margin-bottom: 30px;
    }

    .bts-mb-re-1 {
        margin-top: 30px;
    }

    .tx-cl-re-bobtr {
        margin-top: 20px;
    }

    .mts-tx {
        margin-top: -40px;
    }

    .shares3-bg {
        background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
        padding-bottom: 154px !important;
    }

    .shares4-bg {
        background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
}


.pamm3-txt-new {
    font-size: 19px;
    margin-left: 8px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.pamm3-txt-new:hover {
    color: #d9072d;
}

.mobile-pamm-row-new {
    padding-top: 25px;
    padding-bottom: 25px;
}

.paam3-ico {
    height: 25px;
}

.width-setting {
    max-width: 400px !important;
}


_:-webkit-full-screen,
_:-webkit-full-screen-ancestor,
_:-webkit-full-screen-ancestor,
.sec-one-p {
    margin-top: 30px;
    /* animation: mymove1 3s infinite; */
    -webkit-filter: drop-shadow(0 30px 90px #fa807257);
}

@-moz-document url-prefix() {
    .sec-one-p {
        height: 380px;
        margin-top: 30px;
        /* animation: mymove1 3s infinite; */
        filter: drop-shadow(0 30px 90px #fa807257);
    }
}

/* @media (min-width: 414px) {
    .sec-four-imgmob {
        height: 347px !important;
    }
} */

.shares3-bg {
    background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
    padding-bottom: 40px;
    margin-bottom: -189px;
}

.shares4-bg {
    background: linear-gradient(180deg, rgba(253, 193, 191, 1) 0%, rgba(244, 214, 213, 1) 100%);
    padding-bottom: 40px;
    margin-bottom: -189px;
}

.popup-head {
    color: #FF5848;
}

#query-popup {
    background-color: #ff5848;
    border-radius: 7px;
    padding: 1px;
    /* margin-top: 7%;
    margin-bottom: 10%; */
}

#query-popup .modal-header {
    border: none !important;
    padding-bottom: 0px;
}

#query-popup .modal-footer {
    border: none !important;
}

.query-popup-btn {
    background-color: #ff5848;
    border: none;
    width: 200px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    margin-bottom: 30px;
}

.popup-img {
    height: 150px;
    margin-bottom: 40px;
}

.modal-align {
    padding-top: 5%;
}

/*Safari Browser*/
@media only screen and (max-width: 480px) {
    .sec-four-imgmob {
        height: 210px;
    }

    .sec-four-crd1 {
        height: 8%;
        left: -39%;
    }

    .sec-four-crd2 {
        height: 8%;
        left: -1%;
        top: -12%;
    }

    .sec-four-crd3 {
        height: 11%;
        left: 58%;
        top: -53%;
    }
}

/* @media screen and (min-width:0\0) {

 
}   */


@media(max-width:540px) {
    .sec-four-imgmob {
        height: 210px;
        left: 46px;
    }

    .sec-four-crd1 {
        height: 8%;
        left: -14%;
    }

    .sec-four-crd2 {
        height: 8%;
        left: 6%;
        top: -14%;
    }

    .sec-four-crd3 {
        height: 11%;
        left: 54%;
        top: -53%;
    }

}

@media(min-width: 1900px) {
    .crd-img2 {
        top: 147%;
    }

    .crd-img3 {
        top: 119%;
    }

    .crd-img1 {
        top: 97%;
    }

    .sec-four-crd1 {
        height: 9%;
        left: -16%;
        top: -25%;
    }

    .sec-four-crd1 {
        height: 9%;
        left: -13%;
        top: -30%;
    }

    .sec-four-crd3 {
        height: 11%;
        left: 61%;
        top: -51%;
    }
}

@supports (-webkit-hyphens:none) {
    .sec-four-crd1 {
        height: 6%;
        left: -16%;
        top: -263px;
    }

    .sec-four-crd2 {
        height: 6%;
        left: 6%;
        top: -7%;
    }

    .sec-four-crd3 {
        height: 8%;
        left: 60%;
        top: -32%;
    }

    .sec-four-imgmob {
        height: 355px;
        position: relative;
        top: 60px;
        margin-top: -66px;
    }

    .crd-img3 {
        top: 129%;
        left: 31%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }


    .crd-img1 {
        top: 104%;
        left: 6%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }

    .crd-img2 {
        top: 159%;
        left: 8%;
        box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
    }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 3),
                    only screen and (min--moz-device-pixel-ratio: 3),
                    only screen and (-o-min-device-pixel-ratio: 3/1),
                    only screen and (min-device-pixel-ratio: 3)
{  } */
@supports (-webkit-hyphens:none) {
    @media (max-width: 430px) {
        .sec-four-imgmob {
            height: 210px;
            left: 66px;
        }

        .sec-four-crd1 {
            height: 5%;
            left: -18%;
            top: -10%;
            width: 61%;
        }

        .sec-four-crd2 {
            height: 6%;
            left: 3%;
            top: -3%;
        }

        .sec-four-crd3 {
            height: 6%;
            left: 61%;
            top: -23%;
        }

        .crd-img3 {
            top: 110px;
            left: 31%;
            box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
        }


        .crd-img1 {
            top: 45px;
            left: -108px;
            box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
        }

        .crd-img2 {
            bottom: 97px;
            left: -107px;
            box-shadow: rgba(216, 0, 39, 0.1) 0px 10px 15px -3px, rgba(216, 0, 39, 0.05) 0px 4px 6px -2px;
        }
    }

}

.nvhide {
    display: none;
}

/****** DEMO CONTEST PAGE ******/


.index-bg {
    background: linear-gradient(to left, #FF0000, #8B0000);
    height: 590px;
}

.index-trade {
    padding-top: 150px;
    color: hsl(50.38deg 100% 61.57%);
    /* height: 60px; */
    font-size: 15px !important;
}

.global-chat {
    color: #fff;
    font-size: 50px;
}

.rupesss {
    color: hsl(50.35deg 100% 50%);
}

.top-trade {
    color: hsl(50.38deg 100% 61.57%) !important;
    height: 30px;
    font-size: 15px;
}

.btn-1 {
    background-color: hsl(39.6deg 100% 80.39%) !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 30px 12px 30px !important;
    border: none !important;
}

.btn-1:hover {
    background-color: white !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 30px 12px 30px !important;
    border: none !important;
}

.start-trade {
    font-size: 12px;
    color: #fff;
    margin-top: 10px;
}

.reward {
    height: 440px;
    margin-top: 150px;
}

.left-prize {
    display: flex;
    justify-content: end;
}

.line-1 {
    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 22px;
}

.line-index {
    color: red;
}

.rot {
    height: 5px;
    width: 20px;
    background-color: red;
    rotate: 90deg;
    margin-top: -15.9px;
}

.black-box {
    background-color: #000;
    height: 740px;
}

.winner-award {
    color: hsl(358.71deg 78.81% 46.27%);
    padding-top: 40px;
    padding-bottom: 15px;
}

.per-around {
    color: hsl(39.81deg 44.07% 53.73%);
    height: 40px;
}

.index-winner-top {
    color: #fff;
}

.top-tr {
    color: hsl(39.81deg 44.07% 53.73%);
}

.btn-2 {
    background-color: hsl(39.6deg 100% 80.39%) !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 50px 12px 50px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none !important;
}

.btn-2:hover {
    background-color: #fff !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 50px 12px 50px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none !important;
}

.mo-spend {
    margin-left: 80px;
}

.mobile-center {
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
}

.box-card {
    width: 9rem;
    height: 10.5rem !important;
    background-color: hsl(358.71deg 78.81% 46.27%) !important;
}

.first {
    height: 100px;
    margin-top: -35px;
    margin-left: -37px;
}

.card-title-s {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -60px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 25px;
    height: 90px;
}

.rupess {
    color: #FFC107 !important;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 30px;
    font-weight: 600;
}

.card-title-1 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: -60px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 25px;
    height: 95px;
}

.move-card {
    margin-top: 75px;
}

.move-do {
    margin-top: 40px;
}

.white-bg {
    background-color: #fff;
    /* z-index: 1; */
    position: relative;
    margin-top: -30px;
    padding-top: 1px;
}

.mobile-tab {
    height: 400px;
    margin-top: 50px;
}

.one {
    border: 1px solid hsl(358.71deg 78.81% 46.27%);
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    display: grid;
    color: hsl(358.71deg 78.81% 46.27%);
}

.linesa {
    background-color: red;
    height: 2px;
    width: 142px;
    rotate: 90deg;
    margin-top: 71px;
    margin-left: -45px;
}

.acc_color1 {
    color: hsl(358.71deg 78.81% 46.27%);

}

.reg {
    color: hsl(358.71deg 78.81% 46.27%);
    height: 30px;
}

.are-ok-trade {
    height: 30px;
}

.join {
    color: #a87c15 !important;
    padding-top: 50px;
}

.co-move {
    margin-top: 10px !important;
    margin-left: -50px !important;
}

.linesas {
    background-color: red;
    height: 2px;
    width: 77px;
    rotate: 90deg;
    margin-top: 37px;
    margin-left: -14px;
}

.mos {
    margin-top: 30px !important;
}

.line-2 {

    margin-top: 70px !important;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 22px;

}

.reg-pages {
    color: #000;
}

.red-card-pages {
    width: 20rem;
    height: 25rem !important;
    background-color: hsl(358.95deg 68.25% 49.41%) !important;
    display: flex;
    justify-content: center !important;
    margin-top: -50px;
}

.card-titles-2 {
    background-color: hsl(359.36deg 80.34% 54.12%) !important;

    text-align: center;
    display: grid;
    align-items: center;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.roun-page {
    text-align: center;
    display: grid;
    align-items: center;
    color: #fff;
    height: 40px;

}

.com {
    text-align: center;
    display: grid;
    align-items: center;
    color: #fff;
    font-size: 12px;
}

.mar {
    text-align: center;
    display: grid;
    align-items: center !important;
    color: #fff;
    background-color: hsl(359.36deg 80.34% 54.12%) !important;
    padding: 15px 0px 15px 0px !important;
    margin-top: -30px;
    line-height: 30px;
}

.csc {
    flex: 0 !important;
}

.mars {
    text-align: center;
    display: grid;
    align-items: center !important;
    color: #fff;
}

.card-titles {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-3 {
    background-color: hsl(39.6deg 100% 80.39%) !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 50px 12px 50px !important;
    margin-top: 10px;
    margin-bottom: 22px;
    border: none !important;
}

.btn-3:hover {
    background-color: #fff !important;
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
    padding: 12px 50px 12px 50px !important;
    margin-top: 10px;
    margin-bottom: 22px;
    border: none !important;
}

.input-design-bg {
    background-color: hsl(0deg 100% 92.75%) !important;
}

.sear {
    margin-top: 65px !important;
}

.btn-search {
    background-color: hsl(359.36deg 80.34% 54.12%) !important;
}

.table thead tr th {
    background-color: hsl(359.36deg 80.34% 54.12%) !important;
    color: #fff;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.table tbody tr td {
    text-align: center;
}

.table tbody .lite-pnk td {
    background-color: hsl(0deg 100% 88.04%) !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.table tbody .lite-1 td {
    background-color: hsl(0deg 100% 92.75%) !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.table tbody .chnage-red td {
    color: hsl(359.36deg 80.34% 54.12%) !important;
}

.table tbody tr td {
    font-weight: 600;
}

.below-index {
    margin-left: 25px !important;
}

.trade-laptop {
    height: 406px;
    margin-top: 40px;
}

.con-bg {
    background-color: hsl(358.96deg 68.38% 49.61%) !important;
}

.list-group-item {
    border: none !important;
}

.list-group-item::marker {
    color: #e82c2e;
    font-size: 12px;
}

.acc-change {
    padding-top: 60px;
}

.colos {
    font-weight: 600;
}

.dsd {
    margin-bottom: 100px !important;

}

.index-last {
    padding-top: 70px;
    padding-bottom: 100px;
}

.acc-change .accordion-button::after {
    background-image: url(../images/plus.svg) !important;
    background-size: 70% !important;
}

.acc-change .accordion-button:not(.collapsed)::after {
    background-image: url(../images/Vector.svg) !important;
    transform: none !important;
}

.ul {
    list-style: disc;
}

.ul li::marker {
    color: #e82c2e;
    font-size: 12px;
}

.ul .li {
    color: #000;
}

.mo-down {
    margin-top: 60px !important;
}

.acc-change .accordion-button:focus {
    z-index: 3;
    border-color: #fff !important;
    outline: 0;
    box-shadow: none !important;
}

.acc-change .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #fadcde !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.fqa {
    height: 350px;
}

.place::placeholder {
    color: #ff5848 !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: hsl(0deg 100% 92.75%) !important;
    outline: 0;
    box-shadow: none !important;
}

/* mobile */
@media (max-width:578px) {

    /* .ul .lia {
        height: 75px;
    } */
    .acc-change {
        padding-top: 0px;
    }

    .reward {
        height: 440px;
        margin-top: 25px !important;
    }

    .are-ok-trade {
        height: 30px;
        font-size: 12px;
    }

    .dsd {
        margin-bottom: 100px !important;
        padding-bottom: 0px;
    }

    .fqa {
        height: 255px;
        margin-top: 30px;
    }

    .index-bg {
        background-color: hsl(358.71deg 78.81% 46.27%) !important;
        height: auto;
    }

    .black-box {
        background-color: #000;
        height: auto !important;
        padding-bottom: 50px;
    }

    .move-card {
        margin-top: 0px;
    }

    .mobile-space {
        margin-top: 20px;
    }

    .mobile-center {
        display: flex;
        justify-content: center;
        margin-top: 20px !important;
    }

    .trade-laptop {
        height: 240px;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .sear {
        margin-top: 10px !important;
    }

    .line-2 {
        margin-top: 70px !important;
        margin-bottom: 30px;
        margin-left: 0px;
        font-weight: 600;
    }

    .co-move {
        margin-top: 10px !important;
        margin-left: -20px !important;
    }

    .line-3 {
        margin-top: 0px !important;
        margin-bottom: 30px;
        font-weight: bold;
    }

    .footer-bg {
        background-color: #000;
        height: auto !important;
        padding-bottom: 40px;
        font-weight: 600;
    }

    .index-trade {
        padding-top: 108px;
        color: hsl(50.38deg 100% 61.57%);
        height: 150px;
    }

    .index-winner-top br {
        display: none !important;
    }

    .reg-page br {
        display: none !important;
    }

    .below-index br {
        display: none !important;
    }

    .ul .lis br {
        display: none !important;
    }

    .copy-footer br {
        display: none !important;
    }

    .global-chat {
        color: #fff;
        font-size: 30px;
    }

    .hero-name {
        font-weight: bolder;
        color: #fff !important;
        margin-left: 0px;
    }

    .red-card-pages {
        width: 20rem;
        height: 22rem !important;
        background-color: hsl(358.95deg 68.25% 49.41%) !important;
        display: flex;
        justify-content: center !important;
        margin-top: 35px;
    }

    .mo-spend {
        margin-left: 20px;
    }

    .moss {
        margin-left: 0px !important;
    }

    .rot {
        height: 5px;
        width: 20px;
        background-color: red;
        rotate: 90deg;
        margin-top: -15.8px;
    }

    .mobile-tab {
        height: 350px;
        margin-top: 50px;
    }

    .faq {
        margin-top: 45px;
    }

}

.terms-bg {
    margin-top: 40px !important;
}

/*Duel Demo Contest Page Starts */

.global-chat-two {
    color: #fff;
    font-size: 45px;
    margin-top: 180px;
}

.top-trade-two {
    color: hsl(50.38deg 100% 61.57%) !important;
    height: 30px;
    font-size: 30px;
}

.black-box-two {
    background-color: #000;
    height: 410px;
}

.winner-award-two {
    color: hsl(358.71deg 78.81% 46.27%);
    padding-top: 30px;
    padding-bottom: 15px;
}

.rewards-img {
    height: 400px;
}

.whyduel-img {
    height: 350px;
}

.mo-spend-two {
    margin-left: 40px;
}

.terms-lh {
    line-height: 40px;
}

.terms-img {
    height: 400px;
}

@media (max-width:578px) {
    .global-chat-two {
        color: #fff;
        font-size: 30px;
        margin-top: 120px;
    }

    .black-box-two {
        height: 610px;
    }

    .rewards-img {
        height: 250px;
    }

    .whyduel-img {
        height: 250px;
        width: 290px;
    }

    .mo-spend-two {
        margin-left: 20px;
    }

    .col-reverse {
        flex-direction: column-reverse;
    }

    .terms-lh {
        line-height: 30px;
    }

    .terms-img {
        height: 340px;
    }
}